import React from "react"
import CowHeadIcon from "@/assets/icons/farm/cow-head-icon.tsx"
import GenericPicture from "@/components/generic-picture/generic-picture.tsx"
import { type Animal, getCurrentAnimalImage } from "@/features/farm"
import { LIGHT_GREY } from "@/utils/constants/colors-constants.ts"

interface TaskCardPillAnimalInfoProps {
  animals: Animal[]
}

const TaskCardPillAnimalInfo: React.FC<TaskCardPillAnimalInfoProps> = ({
  animals,
}) => {
  return (
    <>
      {animals.length > 1 ? (
        <div
          className={
            "box-border grid h-[30px] w-fit max-w-full grid-cols-[1fr_25px] items-center rounded-[15px] bg-[var(--background-color)] pl-2.5 pr-[5px]"
          }
        >
          <div className={"mx-1 min-w-4 truncate text-[12px]"}>
            {animals.length}
          </div>
          <CowHeadIcon fill={"transparent"} stroke={LIGHT_GREY} />
        </div>
      ) : (
        <div
          className={
            "flex h-[30px] items-center gap-1 rounded-[15px] bg-[var(--background-color)] pr-1"
          }
        >
          <GenericPicture
            src={getCurrentAnimalImage(animals[0])}
            alt={animals[0].ain}
            size={25}
            borderRadius={50}
          />
          <div>
            <p className={"mx-1 min-w-4 truncate text-[12px]"}>
              {animals[0].name}
            </p>
          </div>
        </div>
      )}
    </>
  )
}

export default TaskCardPillAnimalInfo
