import FemaleCardIcon from "@/assets/icons/farm/female-card-icon.tsx"
import MaleCardIcon from "@/assets/icons/farm/male-card-icon.tsx"

export enum REGISTRY_ENTRY_ENUM {
  BIRTH = "Birth",
  PURCHASE = "Purchase",
}

export enum PRODUCTION_ENUM {
  DIARY = "Dairy",
  MEAT = "Meat",
}

export enum EXIT_WAY_ENUM {
  SALE = "Sale",
  SACRIFICE = "Sacrifice",
  DEATH = "Death",
  LOSS = "Loss",
  TRANSFER = "Transfer",
}

export enum GENDERS_ENUM {
  ALL_GENDERS,
  MALE,
  FEMALE,
}

export const ANIMAL_GENDERS = {
  ALL_GENDERS: {
    label: "ALL_GENDERS",
    value: GENDERS_ENUM.ALL_GENDERS,
  },
  MALE: {
    label: "Male",
    value: GENDERS_ENUM.MALE,
  },
  FEMALE: {
    label: "Female",
    value: GENDERS_ENUM.FEMALE,
  },
}

export function getGenderIcon(gender: string) {
  switch (gender) {
    case "Female":
      return <FemaleCardIcon />
    case "Male":
      return <MaleCardIcon />
    default:
      return null
  }
}

export enum ANIMAL_STATUS_ENUM {
  ALL_STATUSES = "ALL_STATUSES",
  HEAT = "HEAT",
  GESTATING = "GESTATING",
  LACTATING = "LACTATING",
  HEALTHY = "HEALTHY",
  DRY = "DRY",
  UNDER_TREATMENT = "UNDER_TREATMENT",
}
