import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { TopBarContainer } from "../top-bar-container"
import BackArrow from "@/assets/icons/misc/back-arrow.tsx"
import NewPlusIcon from "@/assets/icons/misc/new-plus-icon.tsx"
import commonStyles from "@/common.module.css"
import styles from "@/components/top-bars/top-bars-common.module.css"
import useNavigationFallback from "@/hooks/use-navigation-fallback.ts"

interface MobileTopBarProps {
  navigateTo?: string
  title: string
  onIconClick?: () => void
  onBackClick?: () => void
  icon?: React.ReactNode
  titleInfo?: React.ReactNode
}

const MobileTopBar: React.FC<MobileTopBarProps> = ({
  title,
  navigateTo,
  onIconClick,
  icon,
  titleInfo,
  onBackClick,
}) => {
  const { handleBackNavigation } = useNavigationFallback()
  const { t } = useTranslation()

  const handleBackClick = () => {
    if (onBackClick) {
      onBackClick()
    } else {
      handleBackNavigation()
    }
  }

  return (
    <TopBarContainer>
      <div className={styles.left}>
        <div className={styles.actionIcon} onClick={handleBackClick}>
          <BackArrow />
        </div>
        <div className={styles.title}>{title}</div>
        {titleInfo}
      </div>
      {onIconClick && icon && (
        <div onClick={onIconClick} className={styles.actionIcon}>
          {icon}
        </div>
      )}
      {navigateTo && (
        <Link className={styles.actionIcon} to={navigateTo}>
          <span className={commonStyles["sr-only"]}>{t("newAnimal")}</span>
          {icon ? icon : <NewPlusIcon />}
        </Link>
      )}
    </TopBarContainer>
  )
}

export default MobileTopBar
