import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const HiveFramesBroodIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      fill="currentColor"
      width="16"
      height="14"
      viewBox="0 0 16 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6528 0.942871L11.5098 0.946533L11.3429 0.945654L11.26 1.08203L10.2119 2.80688L8.41516 2.81055L7.46338 1.24683L7.38327 1.10854L7.2164 1.10679L5.07352 1.11042L4.90567 1.10948L4.82364 1.24589L3.78604 2.95425L1.78525 2.95791L1.61832 2.95703L1.53537 3.09344L0.464416 4.85676L0.382385 4.99316L0.467224 5.13051L1.41711 6.69145L0.387146 8.38885L0.304199 8.52525L0.389099 8.6626L1.46103 10.4232L1.54016 10.5623L1.70801 10.5632L3.77747 10.5596L4.72443 12.1132L4.80362 12.2523L4.97147 12.2533L7.03427 12.2496L7.98602 13.8134L8.06616 13.9516L8.23309 13.9535L10.376 13.9498L10.5381 13.9525L10.6211 13.8161L11.6634 12.1059L13.6643 12.1023L13.8264 12.105L13.9084 11.9686L14.9851 10.2035L15.0671 10.0671L14.9823 9.92972L13.9103 8.16915L13.8311 8.03087L13.6633 8.02911L11.6005 8.03274L10.7708 6.67044L11.776 5.01974L13.6537 5.01607L13.8159 5.01886L13.8979 4.88245L14.9736 3.11731L15.0566 2.98088L14.9717 2.84354L13.8998 1.08297L13.8206 0.944746L13.6528 0.94293L13.6528 0.942871ZM13.4897 1.48943L14.3899 2.98633L13.4887 4.47133L11.6739 4.47405L10.7698 2.9827L11.6748 1.49131L13.4897 1.48943ZM3.76508 3.50084L4.66534 4.99773L3.7641 6.48273L1.94922 6.48549L1.04517 4.99407L1.9502 3.50272L3.76508 3.50087V3.50084ZM7.02756 5.20006L7.89822 6.65025L6.97128 8.17735L5.20798 8.18011L4.30771 6.69426L5.21274 5.20284L7.02762 5.20009L7.02756 5.20006ZM10.2415 6.88006L11.1379 8.37238L10.2367 9.85832L8.42188 9.86008L7.54541 8.41448L8.47812 6.88281L10.2415 6.88006ZM13.5001 8.57562L14.4004 10.0725L13.4992 11.5575L11.6843 11.5594L10.7803 10.0689L11.6853 8.57746L13.5002 8.57562H13.5001ZM6.95319 8.72767L7.85059 10.2208L6.9494 11.7058L5.13452 11.7077L4.23044 10.2163L5.13254 8.73042L6.95313 8.72767H6.95319Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default HiveFramesBroodIcon
