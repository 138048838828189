import React from "react"
import { useTranslation } from "react-i18next"
import styles from "./error-boundary.module.css"
import LogoBrown from "@/assets/icons/logos/logo-brown.tsx"
import { useAppSelector } from "@/redux/hooks.ts"
import { persistor } from "@/redux/store.ts"

const ErrorBoundary: React.FC = () => {
  const token = useAppSelector((state) => state.auth.token)
  const { t } = useTranslation()

  const reload = () => {
    localStorage.clear()
    localStorage.setItem("token", token as string)
    persistor.purge().then(() => window.location.reload())
  }

  return (
    <div className={styles.errorBoundaryWrapper}>
      <LogoBrown width={150} height={150} />
      <div className={"flex flex-col items-center justify-center"}>
        <h1 className={"text-[20px] font-semibold text-neutral-600"}>
          {t("weAreSorry")}
        </h1>
        <h2 className={"text-[20px] font-semibold text-neutral-400"}>
          {t("anErrorOccurred")}
        </h2>
      </div>
      <button className={styles.reloadButton} onClick={reload}>
        {t("reloadApp")}
      </button>
    </div>
  )
}

export default ErrorBoundary
