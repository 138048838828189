import { toast } from "react-toastify"
import * as Yup from "yup"
import { type QueenInput, QueenOrigins } from "@/features/bees/types/apiaries"
import CustomErrorToast from "@/utils/toast-config/error-toast"

export const queenSchema = Yup.object().shape({
  name: Yup.string()
    .required("nameRequired")
    .min(2, "nameMinLength")
    .max(50, "nameMaxLength")
    .matches(/^[A-Za-z0-9-\s]+$/, "nameFormat"),

  origin: Yup.string()
    .required("originRequired")
    .oneOf(Object.values(QueenOrigins), "invalidOrigin"),

  description: Yup.string().max(1000, "descriptionMaxLength").nullable(),

  picture: Yup.string()
    .nullable()
    .transform((value) => (value === "" ? null : value)),
})

export const validateQueen = async (queen: Partial<QueenInput> = {}) => {
  try {
    await queenSchema.validate(queen, { abortEarly: false })
    return true
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      toast.error(<CustomErrorToast errors={error.errors} />)
    }
    return false
  }
}
