import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const RoundedCheck: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="20" height="20" rx="10" fill="#E0E0E0" />
      <path
        d="M4 11.4L7.143 15L15 6"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default RoundedCheck
