import React from "react"

const ArrowRight: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 9.106 14.202"
      {...props}
    >
      <path
        d="M1577.832,68l8,6.956-8,6.48"
        transform="translate(-1577.504 -67.62)"
        fill="none"
        stroke={props.stroke || "#414141"}
        strokeWidth="2"
      />
    </svg>
  )
}

export default ArrowRight
