import langstrohHive from "@/assets/images/langstroth-hive.png"
import otherHive from "@/assets/images/other-hive.png"
import topBarHive from "@/assets/images/top-bar-hive.png"
import warreHive from "@/assets/images/warre-hive.png"
import { type HiveSummary, HiveType } from "@/features/bees/types/apiaries.ts"

export const getImageForHive = (hive: HiveSummary) => {
  switch (hive.hive_type) {
    case HiveType.Langstroth:
      return langstrohHive
    case HiveType.TopBar:
      return topBarHive
    case HiveType.Warre:
      return warreHive
    default:
      return otherHive
  }
}
