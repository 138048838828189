import React from "react"

interface PendingCircleProps {
  onClick?: () => void
  className?: string
}

const PendingCircle: React.FC<PendingCircleProps> = ({
  onClick,
  className,
}) => {
  const defaultClassName = `border border-dark-grey w-[24px] h-[24px] rounded-full bg-[var(--background-color)] box-border`
  const combinedClassName = className
    ? `${defaultClassName} ${className}`
    : defaultClassName

  return <div className={combinedClassName} onClick={onClick}></div>
}

export default PendingCircle
