import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const RoundedCheckDone: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <rect width="20" height="20" rx="10" fill="#E4F3E4" />
      <path
        d="M3.33337 10.75L5.95254 13.75L12.5 6.25M16.6667 6.3025L9.52337 13.8025L9.16671 13.3333"
        stroke="#33A133"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default RoundedCheckDone
