import { useNavigate } from "react-router-dom"
import BigPlusIcon from "@/assets/icons/misc/big-plus-icon.tsx"
import { cn } from "@/utils/styles.ts"

interface WidgetAddItemProps {
  navigateTo: string
  className?: string
}

const EmptyContentCreateItemButton: React.FC<WidgetAddItemProps> = ({
  navigateTo,
  className,
}) => {
  const navigate = useNavigate()

  return (
    <div
      className={cn(
        "fp-border flex h-[120px] items-center justify-center rounded-[15px] bg-white",
        className,
      )}
      onClick={() => navigate(navigateTo)}
    >
      <BigPlusIcon height={65} />
    </div>
  )
}

export default EmptyContentCreateItemButton
