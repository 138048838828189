import { skipToken } from "@reduxjs/toolkit/query"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import EditIcon from "@/assets/icons/misc/edit-icon.tsx"
import genericHiveImg from "@/assets/images/generic_hive.webp"
import GenericPicture from "@/components/generic-picture/generic-picture.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import EventsWidget from "@/features/animal-events/components/events-widget/events-widget.tsx"
import {
  useGetHiveByIdQuery,
  useGetQueenByIdQuery,
} from "@/features/bees/api/apiaries-api.ts"
import HiveDetails from "@/features/bees/components/hive-details/hive-details.tsx"
import { QueenWidget } from "@/features/bees/components/queen-widget/queen-widget.tsx"
import TasksWidget from "@/features/home/components/tasks-widget/tasks-widget.tsx"
import NotesWidget from "@/features/notes/components/notes-widget/notes-widget.tsx"
import {
  ALL_HIVES_PATH,
  APIARIES_SCREEN_PATH,
} from "@/utils/constants/routes.ts"

const HiveView = () => {
  const { t } = useTranslation()
  const { apiaryId, hiveId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { data: hive } = useGetHiveByIdQuery(
    apiaryId && hiveId ? { apiaryId, hiveId } : skipToken,
  )
  const queenId = hive?.queen ? hive?.queen.id : ""
  const { data: queen } = useGetQueenByIdQuery(
    hiveId && apiaryId && queenId
      ? {
          hiveId,
          apiaryId,
          queenId,
        }
      : skipToken,
  )

  const title = hive?.code || t("isLoading")
  const editHiveNavigationString = `${APIARIES_SCREEN_PATH}/${apiaryId}${ALL_HIVES_PATH}/${hiveId}/edit`

  const handleBackClick = () => {
    const isPrevANewRoute = location.state?.previousPath?.includes("new")
    const isPrevQueenView = location.state?.previousPath?.includes("queen")

    if (isPrevANewRoute || isPrevQueenView) {
      navigate(`${APIARIES_SCREEN_PATH}/${apiaryId}`, {
        state: { previousPath: location.pathname },
      })
    } else {
      navigate(-1)
    }
  }

  return (
    <>
      <MobileTopBar
        title={title}
        navigateTo={editHiveNavigationString}
        icon={<EditIcon />}
        onBackClick={handleBackClick}
      />
      <PageContentWrapperLarge className={"flex flex-col gap-4 p-4"}>
        <GenericPicture
          src={hive?.picture || genericHiveImg}
          alt={t("hiveImage")}
          borderRadius={12}
          height={200}
        />
        {hive && <HiveDetails hive={hive} queen={queen} />}
        <QueenWidget queen={queen} />
        <TasksWidget tasks={hive?.tasks} hiveId={hive?.id} />
        <NotesWidget notes={hive?.notes} hiveId={hive?.id} />
        <EventsWidget animalId={hive?.id || ""} />
      </PageContentWrapperLarge>
    </>
  )
}

export default HiveView
