import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

export const ColorPalleteIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
    >
      <g clip-path="url(#clip0_3001_3134)">
        <path
          d="M9 0.5625C4.0275 0.5625 0 4.3425 0 9C0 13.6575 4.0275 17.4375 9 17.4375C14.2762 17.4375 10.17 14.2537 12.1388 12.3187C12.9937 11.475 13.9725 11.34 14.8837 11.34C15.3 11.34 15.705 11.3737 16.0763 11.3737C17.19 11.3737 18.0112 11.115 18.0112 9.01125C18 4.3425 13.9725 0.5625 9 0.5625ZM16.4812 9.9225C16.425 9.93375 16.3013 9.945 16.065 9.945C15.9075 9.945 15.7387 9.945 15.5588 9.93375C15.345 9.93375 15.12 9.9225 14.8725 9.9225C13.8713 9.9225 12.4087 10.0688 11.1375 11.3063C9.82125 12.6112 10.125 14.2313 10.305 15.21C10.3387 15.4125 10.395 15.705 10.4062 15.885C10.2262 15.9412 9.82125 16.0312 8.98875 16.0312C4.80375 16.0312 1.395 12.8813 1.395 9C1.395 5.11875 4.815 1.96875 9 1.96875C13.185 1.96875 16.5938 5.11875 16.5938 9C16.5938 9.5625 16.5262 9.8325 16.4812 9.9225Z"
          fill="white"
        />
        <path
          d="M6.63754 10.6538C5.47879 10.6538 4.54504 11.5538 4.54504 12.6675C4.54504 13.7812 5.49004 14.6813 6.63754 14.6813C7.78504 14.6813 8.73004 13.7812 8.73004 12.6675C8.73004 11.5538 7.78504 10.6538 6.63754 10.6538ZM6.63754 13.2975C6.24379 13.2975 5.91754 13.0163 5.91754 12.6675C5.91754 12.3188 6.24379 12.0375 6.63754 12.0375C7.03129 12.0375 7.35754 12.3188 7.35754 12.6675C7.35754 13.0163 7.03129 13.2975 6.63754 13.2975ZM6.41254 8.13375C6.41254 7.02 5.46754 6.12 4.32004 6.12C3.17254 6.12 2.22754 7.02 2.22754 8.13375C2.22754 9.2475 3.17254 10.1475 4.32004 10.1475C5.46754 10.1475 6.41254 9.2475 6.41254 8.13375ZM4.32004 8.76375C3.92629 8.76375 3.60004 8.4825 3.60004 8.13375C3.60004 7.785 3.92629 7.50375 4.32004 7.50375C4.71379 7.50375 5.04004 7.785 5.04004 8.13375C5.04004 8.4825 4.71379 8.76375 4.32004 8.76375ZM8.29129 2.8125C7.13254 2.8125 6.19879 3.7125 6.19879 4.82625C6.19879 5.94 7.14379 6.84 8.29129 6.84C9.43879 6.84 10.3838 5.94 10.3838 4.82625C10.3838 3.7125 9.43879 2.8125 8.29129 2.8125ZM8.29129 5.45625C7.89754 5.45625 7.57129 5.175 7.57129 4.82625C7.57129 4.4775 7.89754 4.19625 8.29129 4.19625C8.68504 4.19625 9.01129 4.4775 9.01129 4.82625C9.01129 5.175 8.68504 5.45625 8.29129 5.45625ZM11.07 6.93C11.07 8.04375 12.015 8.94375 13.1625 8.94375C14.31 8.94375 15.255 8.04375 15.255 6.93C15.255 5.81625 14.31 4.91625 13.1625 4.91625C12.015 4.91625 11.07 5.81625 11.07 6.93ZM13.8825 6.93C13.8825 7.27875 13.5563 7.56 13.1625 7.56C12.7688 7.56 12.4425 7.27875 12.4425 6.93C12.4425 6.58125 12.7688 6.3 13.1625 6.3C13.5563 6.3 13.8825 6.58125 13.8825 6.93Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3001_3134">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
