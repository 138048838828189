import styles from "./no-desktop-screen.module.css"
import FlipPhoneIcon from "@/components/under-construction/flip-phone-icon.tsx"
import useIsDesktop from "@/hooks/use-is-desktop.ts"
import useIsPortrait from "@/hooks/use-is-portrait.ts"

// If the orientation is landscape but not wide enough to be desktop
const LandscapeBlock = () => {
  const isPortrait = useIsPortrait()
  const isDesktop = useIsDesktop()

  if (isDesktop) {
    return null
  }

  if (isPortrait) {
    return null
  }

  return (
    <div className={styles.wideScreenMessage}>
      <div className={styles.wideScreenMessageContent}>
        <FlipPhoneIcon />
      </div>
    </div>
  )
}

export default LandscapeBlock
