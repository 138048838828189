import { useState, useEffect } from "react"

const useIsPortrait = () => {
  const [isPortrait, setIsPortrait] = useState(() => {
    return window.matchMedia("(orientation: portrait)").matches
  })

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.matchMedia("(orientation: portrait)").matches)
    }

    window.addEventListener("resize", handleOrientationChange)
    window.addEventListener("orientationchange", handleOrientationChange)

    handleOrientationChange()

    return () => {
      window.removeEventListener("resize", handleOrientationChange)
      window.removeEventListener("orientationchange", handleOrientationChange)
    }
  }, [])

  return isPortrait
}

export default useIsPortrait
