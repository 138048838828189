import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const NrBeesBeginningIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      fill="currentColor"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.49981 1.75L6.67481 2.925M9.32481 2.925L10.4998 1.75M6.12481 4.875V4.25C6.12481 3.75272 6.32235 3.27581 6.67398 2.92417C7.02561 2.57254 7.50253 2.375 7.99981 2.375C8.49709 2.375 8.974 2.57254 9.32563 2.92417C9.67726 3.27581 9.87481 3.75272 9.87481 4.25V4.875M5.22481 8H10.7686M5.18731 11.125H10.8123M3.62481 4.875C3.31728 4.88096 3.01595 4.96248 2.74736 5.11238C2.47878 5.26228 2.2512 5.47595 2.08468 5.73455C1.91816 5.99316 1.81782 6.28876 1.7925 6.5953C1.76718 6.90184 1.81766 7.20989 1.93949 7.49232C2.06132 7.77474 2.25076 8.02286 2.4911 8.21479C2.73145 8.40673 3.01533 8.53659 3.3177 8.59293C3.62008 8.64927 3.93168 8.63035 4.22502 8.53785C4.51836 8.44535 4.78444 8.2821 4.99981 8.0625C6.18731 6.75 7.99981 4.875 7.99981 4.875C7.99981 4.875 9.81231 6.75 10.9998 8.0625C11.2152 8.2821 11.4813 8.44535 11.7746 8.53785C12.0679 8.63035 12.3795 8.64927 12.6819 8.59293C12.9843 8.53659 13.2682 8.40673 13.5085 8.21479C13.7489 8.02286 13.9383 7.77474 14.0601 7.49232C14.182 7.20989 14.2324 6.90184 14.2071 6.5953C14.1818 6.28876 14.0815 5.99316 13.9149 5.73455C13.7484 5.47595 13.5208 5.26228 13.2522 5.11238C12.9837 4.96248 12.6823 4.88096 12.3748 4.875H3.62481Z"
        stroke="white"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.1875 7.1875C10.75 7.75 11.0625 8.5 11.125 9.25C11.125 12.875 8 14.25 8 14.25C8 14.25 4.875 12.875 4.875 9.25C4.9375 8.5 5.25 7.75 5.8125 7.1875"
        stroke="white"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default NrBeesBeginningIcon
