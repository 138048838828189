import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import {
  type ApiaryInput,
  type ApiaryResponse,
  type ApiarySummaryResponse,
  type Hive,
  type HiveInput,
  type HiveResponse,
  type Queen,
  type QueenResponse,
} from "@/features/bees/types/apiaries.ts"

const APIARIES_API_BASE_URL = "/apiaries/"

export const apiariesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createApiary: builder.mutation<ApiaryResponse, ApiaryInput>({
      query: (apiary) => ({
        url: APIARIES_API_BASE_URL,
        method: "POST",
        body: apiary,
      }),
      invalidatesTags: [{ type: TagType.Apiaries, id: "LIST" }],
    }),
    getApiaries: builder.query<ApiarySummaryResponse[], void>({
      query: () => APIARIES_API_BASE_URL,
      providesTags: [{ type: TagType.Apiaries, id: "LIST" }],
    }),
    getApiaryById: builder.query<ApiaryResponse, string>({
      query: (id) => APIARIES_API_BASE_URL + `${id}/`,
      providesTags: (_result, _error, id) => [{ type: TagType.Apiaries, id }],
    }),
    deleteApiary: builder.mutation<void, string>({
      query: (id) => ({
        url: APIARIES_API_BASE_URL + `${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: (_queryCache, _error, apiaryId) => [
        { type: TagType.Apiaries, id: "LIST" },
        { type: TagType.Apiaries, id: apiaryId },
        { type: TagType.Hives },
        { type: TagType.Queens },
      ],
    }),
    updateApiary: builder.mutation<
      ApiaryResponse,
      { apiary: Partial<ApiaryInput>; apiaryId: string }
    >({
      query: ({ apiary, apiaryId }) => ({
        url: APIARIES_API_BASE_URL + `${apiaryId}/`,
        method: "PATCH",
        body: apiary,
      }),
      invalidatesTags: (_queryCache, _error, { apiaryId }) => [
        { type: TagType.Apiaries, id: "LIST" },
        { type: TagType.Apiaries, id: apiaryId },
      ],
    }),
    // Hives
    createHive: builder.mutation<HiveResponse, HiveInput>({
      query: (hive) => ({
        url: `${APIARIES_API_BASE_URL}${hive.apiary}/hives/`,
        method: "POST",
        body: hive,
      }),
      invalidatesTags: (_queryCache, _error, hive) => [
        { type: TagType.Hives, id: "LIST" },
        { type: TagType.Apiaries, id: "LIST" },
        { type: TagType.Apiaries, id: hive.apiary },
      ],
    }),
    getApiaryHives: builder.query<HiveResponse[], string>({
      query: (apiaryId) => `${APIARIES_API_BASE_URL}/${apiaryId}/`,
      providesTags: [{ type: TagType.Hives, id: "LIST" }],
    }),
    getAllHivesForFarm: builder.query<HiveResponse[], void>({
      query: () => `${APIARIES_API_BASE_URL}hives/`,
      providesTags: [{ type: TagType.Hives, id: "LIST" }],
    }),
    getHiveById: builder.query<
      HiveResponse,
      { apiaryId: string; hiveId: string }
    >({
      query: ({ apiaryId, hiveId }) =>
        `${APIARIES_API_BASE_URL}${apiaryId}/hives/${hiveId}/`,
      providesTags: (_result, _error, { hiveId }) => [
        { type: TagType.Hives, id: hiveId },
      ],
    }),
    deleteHive: builder.mutation<void, { apiaryId: string; hiveId: string }>({
      query: ({ apiaryId, hiveId }) => ({
        url: `${APIARIES_API_BASE_URL}${apiaryId}/hives/${hiveId}/`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, { apiaryId, hiveId }) => [
        { type: TagType.Hives, id: "LIST" },
        { type: TagType.Hives, id: hiveId },
        { type: TagType.Apiaries, id: "LIST" },
        { type: TagType.Apiaries, id: apiaryId },
      ],
    }),
    updateHive: builder.mutation<
      Hive,
      { apiaryId: string; hiveId: string; hive: Partial<HiveInput> }
    >({
      query: ({ hive, apiaryId, hiveId }) => ({
        url: `${APIARIES_API_BASE_URL}${apiaryId}/hives/${hiveId}/`,
        method: "PATCH",
        body: hive,
      }),
      invalidatesTags: (_result, _error, { apiaryId, hiveId }) => [
        { type: TagType.Hives, id: "LIST" },
        { type: TagType.Hives, id: hiveId },
        { type: TagType.Apiaries, id: "LIST" },
        { type: TagType.Apiaries, id: apiaryId },
      ],
    }),
    // Queens
    createQueen: builder.mutation<
      QueenResponse,
      { apiaryId: string; hiveId: string; queen: Partial<Queen> }
    >({
      query: ({ apiaryId, hiveId, queen }) => ({
        url: `${APIARIES_API_BASE_URL}${apiaryId}/hives/${hiveId}/queen/`,
        method: "POST",
        body: queen,
      }),
      invalidatesTags: (_result, _error, { hiveId }) => [
        { type: TagType.Queens, id: "LIST" },
        { type: TagType.Hives, id: hiveId },
      ],
    }),
    getQueenForHive: builder.query<
      QueenResponse[],
      { apiaryId: string; hiveId: string }
    >({
      query: ({ apiaryId, hiveId }) =>
        `${APIARIES_API_BASE_URL}/${apiaryId}/hives/${hiveId}/queen/`,
      providesTags: (_result, _error, { hiveId }) => [
        { type: TagType.Queens, id: `${hiveId}-LIST` },
      ],
    }),
    getAllQueensForFarm: builder.query<QueenResponse[], void>({
      query: () => `${APIARIES_API_BASE_URL}queens/`,
      providesTags: [{ type: TagType.Queens, id: "LIST" }],
    }),
    getQueenById: builder.query<
      QueenResponse,
      { apiaryId: string; hiveId: string; queenId: string }
    >({
      query: ({ apiaryId, hiveId, queenId }) =>
        `${APIARIES_API_BASE_URL}${apiaryId}/hives/${hiveId}/queen/${queenId}/`,
      providesTags: (_result, _error, { queenId }) => [
        { type: TagType.Queens, id: queenId },
      ],
    }),
    updateQueen: builder.mutation<
      Queen,
      {
        apiaryId: string
        hiveId: string
        queenId: string
        queen: Partial<Queen>
      }
    >({
      query: ({ apiaryId, hiveId, queenId, queen }) => ({
        url: `${APIARIES_API_BASE_URL}${apiaryId}/hives/${hiveId}/queen/${queenId}/`,
        method: "PATCH",
        body: queen,
      }),
      invalidatesTags: (_result, _error, { hiveId, queenId }) => [
        { type: TagType.Queens, id: queenId },
        { type: TagType.Queens, id: "LIST" },
        { type: TagType.Queens, id: `${hiveId}-LIST` },
        { type: TagType.Hives, id: hiveId },
      ],
    }),
    deleteQueen: builder.mutation<
      void,
      { apiaryId: string; hiveId: string; queenId: string }
    >({
      query: ({ apiaryId, hiveId, queenId }) => ({
        url: `${APIARIES_API_BASE_URL}${apiaryId}/hives/${hiveId}/queen/${queenId}/`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, { queenId, hiveId }) => [
        { type: TagType.Queens, id: queenId },
        { type: TagType.Queens, id: `${hiveId}-LIST` },
        { type: TagType.Hives, id: hiveId },
      ],
    }),
  }),
})

export const {
  useGetAllHivesForFarmQuery,
  useGetQueenByIdQuery,
  useDeleteQueenMutation,
  useCreateQueenMutation,
  useGetQueenForHiveQuery,
  useGetAllQueensForFarmQuery,
  useUpdateQueenMutation,
  useGetApiaryHivesQuery,
  useGetHiveByIdQuery,
  useDeleteHiveMutation,
  useCreateHiveMutation,
  useUpdateHiveMutation,
  useCreateApiaryMutation,
  useDeleteApiaryMutation,
  useUpdateApiaryMutation,
  useGetApiariesQuery,
  useGetApiaryByIdQuery,
} = apiariesApi
