import React from "react"
import { useTranslation } from "react-i18next"
import DetailsIcon from "@/assets/icons/bees/details-icon.tsx"
import { DsvsaIcon } from "@/assets/icons/bees/dsvsa-icon.tsx"
import HiveIcon from "@/assets/icons/bees/hive-icon.tsx"
import { TownAddressIcon } from "@/assets/icons/bees/town-address-icon.tsx"
import HiveDetailsRow from "@/features/bees/components/hive-details/hive-details-row.tsx"
import { type ApiaryResponse } from "@/features/bees/types/apiaries.ts"

interface Props {
  apiary: ApiaryResponse
}

export const ApiaryDetails: React.FC<Props> = ({ apiary }) => {
  const { t } = useTranslation()

  return (
    <div
      className={"widget-border box-border flex flex-col gap-5 bg-white p-4"}
    >
      <div className={"text-[20px] font-semibold text-neutral-700"}>
        {t("apiaryDetails")}
      </div>
      <div className={"flex flex-col gap-2"}>
        <HiveDetailsRow
          text={t("registrationCode")}
          value={apiary.registration_code}
        >
          <DsvsaIcon className={"text-transparent"} />
        </HiveDetailsRow>
        <HiveDetailsRow
          text={t("hivesNumber")}
          value={apiary.hives.length.toString()}
        >
          <HiveIcon className={"scale-150"} />
        </HiveDetailsRow>
        <HiveDetailsRow
          text={t("address")}
          className={"flex-col items-start"}
          value={apiary.address}
        >
          <TownAddressIcon className={"text-transparent"} />
        </HiveDetailsRow>
        {apiary.description && (
          <HiveDetailsRow
            text={t("details")}
            value={apiary.description}
            className={"flex-col items-start"}
          >
            <DetailsIcon className={"scale-150"} />
          </HiveDetailsRow>
        )}
      </div>
    </div>
  )
}
