import { cn } from "@/utils/styles.ts"

interface Props {
  className?: string
}

const RequiredIndicator: React.FC<Props> = ({ className }) => {
  return (
    <span
      className={cn("absolute right-4 text-[18px] text-red-500", className)}
    >
      *
    </span>
  )
}

export default RequiredIndicator
