import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const HiveFrameIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      fill="currentColor"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9348 3.22062L10.6848 0.720625C10.6329 0.61673 10.5531 0.529356 10.4543 0.468306C10.3555 0.407256 10.2416 0.374946 10.1254 0.375H7.00045C6.76357 0.375 6.54732 0.50875 6.44107 0.720625L5.3642 2.875H2.62545C2.38857 2.875 2.17232 3.00875 2.06607 3.22062L0.816072 5.72063C0.772622 5.80746 0.75 5.90322 0.75 6.00031C0.75 6.09741 0.772622 6.19317 0.816072 6.28L2.06607 8.78C2.17232 8.99125 2.38857 9.125 2.62545 9.125H5.3642L6.4417 11.2794C6.49357 11.3832 6.57331 11.4705 6.67199 11.5315C6.77068 11.5926 6.88441 11.6249 7.00045 11.625H10.1254C10.3623 11.625 10.5786 11.4913 10.6848 11.2794L11.9348 8.77938C11.9783 8.69254 12.0009 8.59678 12.0009 8.49969C12.0009 8.40259 11.9783 8.30683 11.9348 8.22L10.8242 6L11.9342 3.77937C11.9776 3.69267 12.0003 3.59705 12.0004 3.50007C12.0005 3.4031 11.9781 3.30742 11.9348 3.22062ZM7.3867 1.625H9.7392L10.6767 3.5L9.7392 5.375H7.3867L6.4492 3.5L7.3867 1.625ZM2.0742 6L3.0117 4.125H5.3642L6.3017 6L5.3642 7.875H3.0117L2.0742 6ZM9.7392 10.375H7.3867L6.4492 8.5L7.3867 6.625H9.7392L10.6767 8.5L9.7392 10.375Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default HiveFrameIcon
