import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const HiveFrameEmptyIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      fill="currentColor"
      width="13"
      height="14"
      viewBox="0 0 13 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.83072 0.198547L12.9915 3.60888L13.0029 10.4497L6.81931 13.86L0.658539 10.4497L0.647095 3.60888L6.83072 0.198547ZM6.87366 1.87029L5.27435 2.75652V4.52898L6.87366 5.41521L8.47296 4.52898V2.75652L6.87366 1.87029ZM10.0885 3.64187L8.48917 4.5281V6.30056L10.0885 7.18679L8.4892 8.07302V9.84636L10.0885 10.7326L11.6879 9.84636V8.07302L10.0886 7.18679L11.6879 6.30056V4.5281L10.0886 3.64187H10.0885ZM3.67026 3.64187L2.07095 4.5281V6.30056L3.67023 7.18679L2.07092 8.07302V9.84636L3.6702 10.7326L5.2695 9.84636V8.07302L3.67023 7.18679L5.26953 6.30056V4.5281L3.67026 3.64187ZM6.87369 5.41796L5.27435 6.30419V8.07665L6.86981 8.96107L5.27435 9.84454V11.6179L6.87366 12.5041L8.47296 11.6179V9.84454L6.8775 8.96107L8.47296 8.07665V6.30419L6.87366 5.41796H6.87369Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default HiveFrameEmptyIcon
