import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import ArrowRight from "@/assets/icons/misc/arrow-right.tsx"
import NewPlusIcon from "@/assets/icons/misc/new-plus-icon.tsx"

interface WidgetHeaderProps {
  title: string
  navigateTo?: string
  addItemGoTo?: () => void
}

const WidgetHeader: React.FC<WidgetHeaderProps> = ({
  title,
  navigateTo,
  addItemGoTo,
}) => {
  const { t } = useTranslation()
  const onClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!navigateTo) {
      event.preventDefault()
    }
  }

  return (
    <div className="flex justify-between gap-2">
      <Link
        to={navigateTo || "#"}
        className={"flex w-[calc(100%-40px)] cursor-pointer items-center gap-2"}
        onClick={onClick}
      >
        <p className="font-montserrat truncate text-[20px] font-semibold text-[var(--color-neutral-700,#414141)]">
          {t(title)}
        </p>
        {navigateTo && (
          <ArrowRight
            className={"h-3.5 w-2.5"}
            stroke={"var(--color-neutral-400)"}
          />
        )}
      </Link>
      {addItemGoTo && (
        <button onClick={addItemGoTo}>
          <NewPlusIcon />
        </button>
      )}
    </div>
  )
}

export default WidgetHeader
