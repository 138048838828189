import {
  type Address,
  type AppPackage,
  type FarmProfile,
  type FarmProfileSummary,
  type UserProfile,
} from "./types.ts"

export enum FARM_TYPE {
  COW = "Cow",
  GOAT = "Goat",
  PORK = "Pork",
  SHEEP = "Sheep",
}

export const initialAddress: Address = {
  street: "",
  city: "",
  state: "",
  country: "",
  extra: "",
  latitude: 0,
  longitude: 0,
}

export const initialAppPackage: AppPackage = {
  name: "",
  description: "",
  ai_assistant_module: false,
  finance_journal_module: false,
  gestational_calendar_module: false,
  bee_module: false,
  bird_module: false,
  cow_module: false,
  goat_module: false,
  pork_module: false,
  sheep_module: false,
}

export const initialFarmProfile: FarmProfile = {
  id: "",
  name: "",
  description: "",
  legal_name: "",
  tin: "",
  picture: "",
  created_at: "",
  updated_at: "",
  address: initialAddress,
  app_package: initialAppPackage,
}

export const initialSummaryFarmProfile: FarmProfileSummary = {
  id: "",
  name: "",
  picture: "",
}

export const initialUserProfile: UserProfile = {
  id: "",
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
  farm: initialSummaryFarmProfile,
  picture: "",
}
