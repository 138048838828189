import { parseISO } from "date-fns"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import styles from "./notes-group.module.css"
import NotesByTimeSpan from "@/features/notes/components/notes-by-time-span/notes-by-time-span.tsx"
import { type Note } from "@/features/notes/types/note.ts"

interface NotesGroupProp {
  notes: Note[]
  title: string
  type: "month" | "year"
}

type GroupedNotesType = { [key: string]: Note[] }

const NotesGroupWrapper: React.FC<NotesGroupProp> = ({
  notes,
  title,
  type,
}) => {
  const { t } = useTranslation()

  function groupNotesByMonth(notes: Note[]): GroupedNotesType {
    const groupedNotes: GroupedNotesType = {}
    notes.forEach((note) => {
      const noteDate = parseISO(note.updated_at)
      const month = noteDate.toLocaleString("ro", { month: "long" })
      if (!groupedNotes[month]) {
        groupedNotes[month] = []
      }
      groupedNotes[month].push(note)
    })
    return groupedNotes
  }

  function groupNotesByYear(notes: Note[]): GroupedNotesType {
    const groupedNotes: GroupedNotesType = {}
    notes.forEach((note) => {
      const noteDate = parseISO(note.updated_at)
      const year = noteDate.getFullYear().toString()
      if (!groupedNotes[year]) {
        groupedNotes[year] = []
      }
      groupedNotes[year].push(note)
    })
    return groupedNotes
  }

  const groupedNotes = useMemo<GroupedNotesType>(() => {
    if (type === "month") {
      return groupNotesByMonth(notes)
    } else {
      return groupNotesByYear(notes)
    }
  }, [notes, type])

  const translatedTitle = t(title)

  const entries = Object.entries(groupedNotes)
  const shouldReverse = type === "year"
  const processedEntries = shouldReverse ? [...entries].reverse() : entries

  return (
    <div>
      <div className={styles["sub-title-updated"]}>{t(translatedTitle)}</div>
      {processedEntries.map(([month, notes]) => (
        <NotesByTimeSpan
          key={month}
          notes={notes}
          subTitle={month}
          enableSeparator={true}
        />
      ))}
    </div>
  )
}

export default NotesGroupWrapper
