import { skipToken } from "@reduxjs/toolkit/query"
import { useLayoutEffect } from "react"
import { useGetHiveByIdQuery } from "@/features/bees/api/apiaries-api.ts"
import { useAppDispatch, useAppSelector } from "@/redux/hooks.ts"
import { setHiveDraft } from "@/redux/slices/hive-draft-slice.ts"

export const useGetHiveDraft = (hiveId?: string, apiaryId?: string) => {
  const dispatch = useAppDispatch()
  const { data, isLoading } = useGetHiveByIdQuery(
    apiaryId && hiveId
      ? {
          apiaryId,
          hiveId,
        }
      : skipToken,
  )
  const draftHive = useAppSelector(
    (state) => state.hiveDraftSlice.drafts[hiveId || ""],
  )

  useLayoutEffect(() => {
    if (data && hiveId && !draftHive) {
      dispatch(
        setHiveDraft({
          id: hiveId,
          draft: data,
        }),
      )
    }
  }, [data, dispatch, draftHive, hiveId])

  return { draftHive, data, isLoading }
}
