import styles from "@/components/filter-scroll/filter-tab.module.css"

interface Props {
  children: React.ReactNode
}

export const FilterScroll: React.FC<Props> = ({ children }) => {
  return (
    <div className={styles["horizontal-scroll"]}>
      <div className={styles["filter-flex"]}>{children}</div>
    </div>
  )
}
