import { toast } from "react-toastify"
import * as Yup from "yup"
import { type ApiaryInput } from "@/features/bees/types/apiaries.ts"
import CustomErrorToast from "@/utils/toast-config/error-toast.tsx"

export const apiarySchema = Yup.object().shape({
  name: Yup.string()
    .required("nameRequired")
    .min(2, "nameMinLength")
    .max(100, "nameMaxLength"),

  address: Yup.string()
    .required("addressRequired")
    .min(5, "addressMinLength")
    .max(200, "addressMaxLength"),

  registration_code: Yup.string()
    .required("registrationCodeRequired")
    .matches(/^[A-Za-z0-9-]+$/, "registrationCodeFormat"),

  description: Yup.string().max(1000, "descriptionMaxLength").nullable(),

  picture: Yup.string()
    .nullable()
    .transform((value) => (value === "" ? null : value)),
})

export const validateApiary = async (apiary: Partial<ApiaryInput> = {}) => {
  try {
    await apiarySchema.validate(apiary, { abortEarly: false })
    return true
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      toast.error(<CustomErrorToast errors={error.errors} />)
    }
    return false
  }
}
