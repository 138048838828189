import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const HiveIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      fill="currentColor"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3002_3507)">
        <path
          d="M13.3019 8.43231C13.3019 7.53856 12.7118 6.78804 11.8983 6.53023C12.0587 6.24377 12.1561 5.91721 12.1561 5.56773C12.1561 4.67398 11.566 3.92346 10.7524 3.66565C10.9129 3.37919 11.0103 3.05262 11.0103 2.70315C11.0103 1.59742 10.1108 0.697937 9.00505 0.697937H4.99463C3.8889 0.697937 2.98942 1.59742 2.98942 2.70315C2.98942 3.05262 3.08682 3.37919 3.24723 3.66565C2.43942 3.92346 1.84359 4.67398 1.84359 5.56773C1.84359 5.91721 1.94098 6.24377 2.1014 6.53023C1.29359 6.78804 0.697754 7.53856 0.697754 8.43231C0.697754 9.15419 1.08161 9.77294 1.65452 10.1281C1.4139 10.4547 1.27067 10.8615 1.27067 11.2969C1.27067 12.4026 2.17015 13.3021 3.27588 13.3021H10.7238C11.8295 13.3021 12.729 12.4026 12.729 11.2969C12.729 10.8615 12.5858 10.4547 12.3451 10.1281C12.9181 9.77294 13.3019 9.15419 13.3019 8.43231ZM4.99463 1.84377H9.00505C9.48057 1.84377 9.86442 2.22762 9.86442 2.70315C9.86442 3.17867 9.48057 3.56252 9.00505 3.56252H4.99463C4.51911 3.56252 4.13525 3.17867 4.13525 2.70315C4.13525 2.22762 4.51911 1.84377 4.99463 1.84377ZM3.8488 4.70835H10.1509C10.6264 4.70835 11.0103 5.09221 11.0103 5.56773C11.0103 6.04325 10.6264 6.4271 10.1509 6.4271H3.8488C3.37327 6.4271 2.98942 6.04325 2.98942 5.56773C2.98942 5.09221 3.37327 4.70835 3.8488 4.70835ZM2.4165 11.2969C2.4165 10.8214 2.80036 10.4375 3.27588 10.4375H5.28109V12.1563H3.27588C2.80036 12.1563 2.4165 11.7724 2.4165 11.2969ZM10.7238 12.1563H8.71859V10.4375H10.7238C11.1993 10.4375 11.5832 10.8214 11.5832 11.2969C11.5832 11.7724 11.1993 12.1563 10.7238 12.1563ZM11.2967 9.29169H8.61546C8.37484 8.6271 7.74463 8.14585 6.99984 8.14585C6.25505 8.14585 5.62484 8.6271 5.38421 9.29169H2.70296C2.22744 9.29169 1.84359 8.90783 1.84359 8.43231C1.84359 7.95679 2.22744 7.57294 2.70296 7.57294H11.2967C11.7722 7.57294 12.1561 7.95679 12.1561 8.43231C12.1561 8.90783 11.7722 9.29169 11.2967 9.29169Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3002_3507">
          <rect
            width="13.75"
            height="13.75"
            fill="currentColor"
            transform="translate(0.125 0.125)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default HiveIcon
