import React from "react"
import styles from "./search-bar.module.css"
import SearchIcon from "@/assets/icons/misc/search-icon.tsx"
import { cn } from "@/utils/styles.ts"

export interface SearchBarProps {
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  placeholder: string
  className?: string
}

const SearchBar: React.FC<SearchBarProps> = ({
  value,
  onChange,
  placeholder,
  className,
}) => {
  return (
    <div className={cn(styles.searchBarWraper, className)}>
      <div className={styles.searchBar}>
        <input
          type="text"
          placeholder={placeholder}
          className={styles.searchInput}
          value={value}
          onChange={onChange}
        />
        <SearchIcon className={styles.searchImage} alt="search" />
      </div>
    </div>
  )
}

export default SearchBar
