import { ApiariesIcon } from "@/assets/icons/bees/apiaries-icon.tsx"

interface Props {
  apiaries: string[]
}

export const TaskCardPillApiary: React.FC<Props> = ({ apiaries }) => {
  return (
    <div
      className={
        "box-border grid h-[30px] w-fit max-w-full grid-cols-[1fr_25px] items-center rounded-[15px] bg-[var(--background-color)] pl-2.5 pr-[5px]"
      }
    >
      <div className={"mx-1 min-w-4 truncate text-[12px]"}>
        {apiaries.length}
      </div>
      <ApiariesIcon className={"text-neutral-500"} />
    </div>
  )
}
