import { skipToken } from "@reduxjs/toolkit/query"
import isEqual from "lodash.isequal"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import DeleteButton from "@/components/delete-button/delete-button.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import EditorTopBar from "@/components/top-bars/editor-top-bar/editor-top-bar.tsx"
import {
  useDeleteApiaryMutation,
  useGetApiaryByIdQuery,
  useUpdateApiaryMutation,
} from "@/features/bees/api/apiaries-api.ts"
import { ApiaryEditor } from "@/features/bees/components/apiary-editor/apiary-editor.tsx"
import { validateApiary } from "@/features/bees/types/apiaries-schema.tsx"
import { type ApiaryInput } from "@/features/bees/types/apiaries.ts"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import useNavigationFallback from "@/hooks/use-navigation-fallback.ts"
import { APIARIES_SCREEN_PATH } from "@/utils/constants/routes.ts"
import { getObjectDifference } from "@/utils/helpers/object-utils.ts"

export const EditApiaryView = () => {
  const { apiaryId } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { openModal } = useModal()
  const [updateApiary] = useUpdateApiaryMutation()
  const [deleteApiaryMutation] = useDeleteApiaryMutation()
  const { handleBackNavigation } = useNavigationFallback()
  const { data: apiary, isLoading } = useGetApiaryByIdQuery(
    apiaryId ?? skipToken,
  )

  const [workingApiary, setWorkingApiary] = useState<ApiaryInput>(
    apiary as ApiaryInput,
  )

  const deleteApiary = async () => {
    if (!apiaryId) return
    const promise = deleteApiaryMutation(apiaryId)
    toast
      .promise(promise, {
        pending: t("deletingApiary"),
        success: t("apiaryDeleted"),
        error: t("apiaryDeleteError"),
      })
      .then(() => navigate(APIARIES_SCREEN_PATH))
  }

  const handleApiaryDeletion = () => {
    openModal(MODALS.BASE_MODAL, {
      title: t("deleteApiaryConfirmation"),
      content: t("apiaryWillBeDeleted"),
      onActionClick: deleteApiary,
    })
  }

  const handleApiaryMutation = async () => {
    if (!(await validateApiary(apiary)) || !apiaryId || !apiary) {
      return
    }
    const diffData = getObjectDifference(apiary, workingApiary)
    const promise = updateApiary({ apiary: diffData, apiaryId }).unwrap()
    toast
      .promise(promise, {
        pending: t("updatingApiary"),
        success: t("apiaryUpdated"),
        error: t("apiaryUpdateError"),
      })
      .then(() => navigate(APIARIES_SCREEN_PATH))
  }

  const handleApiaryChange = (apiary: ApiaryInput) => {
    setWorkingApiary(apiary)
  }

  const hasChanges = !isEqual(workingApiary, apiary)

  const title = apiary?.name || t("loading")

  return (
    <>
      <EditorTopBar
        text={title}
        isModified={hasChanges}
        onSave={handleApiaryMutation}
        onBackClick={handleBackNavigation}
      />
      <PageContentWrapperLarge>
        {!isLoading && workingApiary ? (
          <>
            <ApiaryEditor
              apiary={workingApiary}
              onApiaryChange={handleApiaryChange}
            />
            <DeleteButton
              onClick={handleApiaryDeletion}
              text={t("deleteApiary")}
            />
          </>
        ) : (
          <LoadingAnimation />
        )}
      </PageContentWrapperLarge>
    </>
  )
}
