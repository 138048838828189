import { BROWN } from "@/utils/constants/colors-constants.ts"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const NewPlusIcon: React.FC<GeneralSvgProps> = (props) => {
  const color = props.color || BROWN
  const size = props.size || 30

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 30 30"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_956_5210)">
        <path
          d="M15 0C6.716 0 0 6.716 0 15s6.716 15 15 15 15 -6.715 15 -15S23.285 0 15 0m0 28.155C7.762 28.155 1.875 22.237 1.875 15S7.762 1.875 15 1.875s13.125 5.888 13.125 13.125 -5.888 13.155 -13.125 13.155M21.563 14.063h-5.625V8.438a0.938 0.938 0 0 0 -1.875 0v5.625H8.438a0.938 0.938 0 0 0 0 1.875h5.625v5.625a0.938 0.938 0 0 0 1.875 0v-5.625h5.625a0.938 0.938 0 0 0 0 -1.875"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_956_5210">
          <path width="32" height="32" fill="white" d="M0 0H30V30H0V0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default NewPlusIcon
