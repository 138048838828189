import React from "react"
import NoteCard from "@/features/notes/components/note-card/note-card.tsx"
import { type Note } from "@/features/notes/types/note.ts"

interface NotesByTimeSpanProps {
  notes: Note[]
  subTitle?: string
  enableSeparator?: boolean
  title?: string
}

const NotesByTimeSpan: React.FC<NotesByTimeSpanProps> = ({ notes, title }) => {
  return (
    <div>
      <div className={"mb-4 text-[14px] font-light text-neutral-700"}>
        {title || ""}
      </div>
      <div className={"flex flex-col gap-[10px]"}>
        {notes.map((note) => (
          <NoteCard key={note.id} note={note} />
        ))}
      </div>
    </div>
  )
}

export default NotesByTimeSpan
