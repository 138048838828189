import { RouterProvider } from "react-router-dom"
import { useRegisterSW } from "virtual:pwa-register/react"
import { router } from "./routing/general-routes"

const App = () => {
  useRegisterSW({ immediate: true })

  return <RouterProvider router={router} />
}

export default App
