import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import { type Note } from "@/features/notes"

const NOTES_BASE_URL = "notes/"

export const notesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNotes: builder.query<Note[], void>({
      query: () => ({
        url: NOTES_BASE_URL,
      }),
      transformResponse: (response: Note[]) => {
        return response.sort(
          (a, b) =>
            new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
        )
      },
      providesTags: (result) => {
        if (!result) {
          return [{ type: TagType.Notes, id: "LIST" }]
        }

        const noteTags = result.map(({ id }) => ({
          type: TagType.Notes,
          id: id ?? "UNKNOWN",
        }))

        return [...noteTags, { type: TagType.Notes, id: "LIST" }]
      },
    }),
    getNoteById: builder.query<Note, string>({
      query: (id) => ({
        url: NOTES_BASE_URL + `${id}/`,
      }),
      providesTags: (_result, _error, id) => [{ type: TagType.Notes, id }],
    }),
    postNote: builder.mutation<Note, Partial<Note>>({
      query: (note) => {
        return {
          url: NOTES_BASE_URL,
          method: "POST",
          body: note,
        }
      },
      invalidatesTags: (result, _error, note) => {
        const invalidationTags = [{ type: TagType.Notes, id: "LIST" }]

        if (note.hives && note.hives.length > 0) {
          result?.hives.forEach((hive) => {
            invalidationTags.push({ type: TagType.Hives, id: hive })
          })
        }
        if (note.apiaries && note.apiaries.length > 0) {
          result?.apiaries.forEach((apiary) => {
            invalidationTags.push({ type: TagType.Apiaries, id: apiary })
          })
        }
        if (note.queens && note.queens.length > 0) {
          invalidationTags.push({ type: TagType.Queens, id: "LIST" })
          result?.queens.forEach((queen) => {
            invalidationTags.push({ type: TagType.Queens, id: queen })
          })
        }
        if (note.animals && note.animals.length > 0) {
          invalidationTags.push({ type: TagType.Animals, id: "LIST" })
          result?.animals.forEach((animal) => {
            invalidationTags.push({ type: TagType.Animals, id: animal })
          })
        }

        return invalidationTags
      },
    }),
    updateNote: builder.mutation<Note, Partial<Note>>({
      query: (note) => {
        return {
          url: NOTES_BASE_URL + `${note.id}/`,
          method: "PATCH",
          body: note,
        }
      },
      invalidatesTags: (_result, _error, { id }) => [
        { type: TagType.Notes, id },
      ],
    }),
    deleteNote: builder.mutation<void, string>({
      query: (noteId) => ({
        url: NOTES_BASE_URL + `${noteId}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: TagType.Notes, id: "LIST" }],
    }),
  }),
})

export const {
  useGetNotesQuery,
  useGetNoteByIdQuery,
  useDeleteNoteMutation,
  usePostNoteMutation,
  useUpdateNoteMutation,
} = notesApi
