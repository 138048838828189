import { configureStore, combineReducers } from "@reduxjs/toolkit"
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist"
import storage from "redux-persist/lib/storage"
import animalDraftReducer from "./slices/animal-draft-slice.ts"
import animalFilterReducer from "./slices/animals-filter-slice.ts"
import tokenReducer from "./slices/auth-slice.ts"
import animalEventsDraftReducer from "./slices/events-draft-slice.ts"
import farmProfileReducer from "./slices/farm-profile-draft-slice.ts"
import gestationalCalendarsDraftReducer from "./slices/gestational-calendars-draft-slice.ts"
import hiveDraftReducer from "./slices/hive-draft-slice.ts"
import modalReducer from "./slices/modal-slice.ts"
import notesDraftReducer from "./slices/notes-draft-slice.ts"
import queenDraftReducer from "./slices/queen-draft-slice.ts"
import taskDraftReducer from "./slices/task-draft-slice.ts"
import taskSelectionReducer from "./slices/task-selection-slice.ts"
import transactionReducer from "./slices/transaction-draft-slice.ts"
import userProfileReducer from "./slices/user-profile-draft-slice.ts"
import { apiSlice } from "@/features/api/apiSlice.ts"

const rootReducer = combineReducers({
  auth: tokenReducer,
  modal: modalReducer,
  transactionSlice: transactionReducer,
  userProfileSlice: userProfileReducer,
  farmProfileSlice: farmProfileReducer,
  animalDraftSlice: animalDraftReducer,
  taskDraftSlice: taskDraftReducer,
  notesDraftSlice: notesDraftReducer,
  taskSelectionSlice: taskSelectionReducer,
  animalEventsDraftSlice: animalEventsDraftReducer,
  animalFilterSlice: animalFilterReducer,
  gestationalCalendarsDraftSlice: gestationalCalendarsDraftReducer,
  hiveDraftSlice: hiveDraftReducer,
  queenDraftSlice: queenDraftReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
})

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["modal"],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(apiSlice.middleware),
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
