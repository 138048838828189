import styles from "./skeleton.module.css"
import { cn } from "@/utils/styles.ts"

interface SkeletonProps {
  className?: string
  width?: string | number
  height?: string | number
}

const Skeleton: React.FC<SkeletonProps> = ({ className, width, height }) => {
  return (
    <div
      style={{ ...(width ? { width } : {}), ...(height ? { height } : {}) }}
      className={cn(styles.skeleton, className)}
    ></div>
  )
}

export default Skeleton
