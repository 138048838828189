import React from "react"
import styles from "./no-results-found.module.css"
import ApiaryNotFoundSvg from "@/assets/error-handling/no-apiary-found.svg"
import HiveNotFoundSvg from "@/assets/error-handling/no-hive-found.svg"
import ObjectNotFoundSvg from "@/assets/error-handling/object-not-found.svg"
import { cn } from "@/utils/styles.ts"

interface NoResultsFoundProps {
  message?: string
  children?: React.ReactNode
  className?: string
  image?: "generic" | "hive" | "apiary"
}

const NoResultsFound: React.FC<NoResultsFoundProps> = ({
  message,
  children,
  className,
  image = "generic",
}) => {
  const getImage = () => {
    switch (image) {
      case "hive":
        return HiveNotFoundSvg
      case "apiary":
        return ApiaryNotFoundSvg
      case "generic":
      default:
        return ObjectNotFoundSvg
    }
  }

  return (
    <div className={cn(className, styles.error)}>
      <img src={getImage()} alt="Not Found" className={styles.notFoundImg} />
      {message && <p className={styles.errorMessage}>{message}</p>}
      {children}
    </div>
  )
}

export default NoResultsFound
