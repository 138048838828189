import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import genericQueenImg from "@/assets/images/generic-queen.webp"
import GenericPicture from "@/components/generic-picture/generic-picture.tsx"
import { type Queen } from "@/features/bees/types/apiaries.ts"
import EmptyContentCreateItemButton from "@/features/home/components/empty-content-create-item-button/empty-content-create-item-button.tsx"
import WidgetHeader from "@/features/home/components/widget-header/widget-header.tsx"
import { BROWN } from "@/utils/constants/colors-constants.ts"
import {
  ALL_HIVES_PATH,
  APIARIES_SCREEN_PATH,
} from "@/utils/constants/routes.ts"

interface Props {
  queen: Queen | undefined
}

export const QueenWidget: React.FC<Props> = ({ queen }) => {
  const { t } = useTranslation()
  const { apiaryId, hiveId } = useParams()
  const addNewQueenNavigationString = `${APIARIES_SCREEN_PATH}/${apiaryId}${ALL_HIVES_PATH}/${hiveId}/queen/new`
  const queenViewNavigationString =
    queen &&
    `${APIARIES_SCREEN_PATH}/${apiaryId}${ALL_HIVES_PATH}/${hiveId}/queen/${queen.id}/`

  return (
    <div
      className={"widget-border box-border flex flex-col gap-5 bg-white p-4"}
    >
      <WidgetHeader title={t("queen")} navigateTo={queenViewNavigationString} />
      {queen && (
        <div className={"flex gap-2"}>
          <GenericPicture
            src={queen.picture || genericQueenImg}
            alt={t("queen")}
            size={70}
            borderRadius={12}
            style={{
              border: "1px solid",
              borderColor: BROWN,
            }}
          />
          <div className={"flex flex-col justify-between"}>
            <div className={"text-[16px] font-semibold text-neutral-700"}>
              {queen.name}
            </div>
            <div className={"flex gap-2"}>
              {queen.year && (
                <div
                  className={
                    "rounded-[8px] bg-green-100 px-2 py-1 text-neutral-700"
                  }
                >
                  {queen.year}
                </div>
              )}
              <div
                className={"rounded-[8px] bg-red-50 px-2 py-1 text-neutral-700"}
              >
                {t(queen.origin)}
              </div>
            </div>
          </div>
        </div>
      )}
      {!queen && (
        <EmptyContentCreateItemButton
          navigateTo={addNewQueenNavigationString}
        />
      )}
    </div>
  )
}
