import { useTranslation } from "react-i18next"
import CalendarIcon from "@/assets/icons/bees/calendar-icon.tsx"
import DetailsIcon from "@/assets/icons/bees/details-icon.tsx"
import HiveFrameEmptyIcon from "@/assets/icons/bees/hive-frame-empty-icon.tsx"
import HiveFrameHoneyIcon from "@/assets/icons/bees/hive-frame-honey-icon.tsx"
import HiveFrameIcon from "@/assets/icons/bees/hive-frame-icon.tsx"
import HiveFramesBroodIcon from "@/assets/icons/bees/hive-frames-brood-icon.tsx"
import HiveIcon from "@/assets/icons/bees/hive-icon.tsx"
import IdentificationNumberIcon from "@/assets/icons/bees/identification-number-icon.tsx"
import NrBeesBeginningIcon from "@/assets/icons/bees/nr-bees-beginning-icon.tsx"
import PowerIcon from "@/assets/icons/bees/power-icon.tsx"
import QueenIcon from "@/assets/icons/bees/queen-icon.tsx"
import HiveDetailsRow from "@/features/bees/components/hive-details/hive-details-row.tsx"
import {
  type HiveResponse,
  type QueenResponse,
} from "@/features/bees/types/apiaries.ts"

interface Props {
  hive: HiveResponse
  queen: QueenResponse | undefined
}

const HiveDetails: React.FC<Props> = ({ hive, queen }) => {
  const { t } = useTranslation()

  const totalFrames =
    hive.number_of_brood_frames +
    hive.number_of_empty_frames +
    hive.number_of_honey_frames

  return (
    <div
      className={"widget-border box-border flex flex-col gap-5 bg-white p-4"}
    >
      <div className={"text-[20px] font-semibold text-neutral-700"}>
        {t("hiveDetails")}
      </div>
      <div className={"flex flex-col gap-2"}>
        <HiveDetailsRow text={t("hiveCode")} value={hive.code}>
          <IdentificationNumberIcon className={"scale-150"} />
        </HiveDetailsRow>
        <HiveDetailsRow text={t("hiveType")} value={hive.hive_type}>
          <HiveIcon className={"scale-150"} />
        </HiveDetailsRow>
        {queen?.year && (
          <HiveDetailsRow text={t("queenBirthYear")} value={queen.year}>
            <CalendarIcon className={"scale-150"} />
          </HiveDetailsRow>
        )}
        {queen?.origin && (
          <HiveDetailsRow text={t("queenOrigin")} value={queen.origin}>
            <QueenIcon className={"scale-150"} />
          </HiveDetailsRow>
        )}
        <HiveDetailsRow text={t("framesNumber")} value={totalFrames}>
          <HiveFrameIcon className={"scale-150"} />
        </HiveDetailsRow>
        <HiveDetailsRow text={t("familyPower")} value={"98%"}>
          <PowerIcon className={"scale-150 text-brown-500"} />
        </HiveDetailsRow>
        <HiveDetailsRow text={t("beeCount")} value={hive.bee_count}>
          <NrBeesBeginningIcon className={"scale-150 text-brown-500"} />
        </HiveDetailsRow>
        <HiveDetailsRow
          text={t("broodFramesNumber")}
          value={hive.number_of_brood_frames}
        >
          <HiveFramesBroodIcon className={"scale-150"} />
        </HiveDetailsRow>
        <HiveDetailsRow
          text={t("honeyFramesNumber")}
          value={hive.number_of_honey_frames}
        >
          <HiveFrameHoneyIcon className={"scale-150"} />
        </HiveDetailsRow>
        <HiveDetailsRow
          text={t("emptyFramesNumber")}
          value={hive.number_of_empty_frames}
        >
          <HiveFrameEmptyIcon className={"scale-150"} />
        </HiveDetailsRow>
        <HiveDetailsRow
          text={t("details")}
          value={hive.description}
          className={"flex-col items-start"}
        >
          <DetailsIcon className={"scale-150"} />
        </HiveDetailsRow>
      </div>
    </div>
  )
}

export default HiveDetails
