import { type ReactNode } from "react"
import { cn } from "@/utils/styles"

export function TopBarContainer({
  children,
  className,
}: {
  children: ReactNode
  className?: string
}) {
  return (
    <div
      className={cn(
        "z-10 flex h-[var(--topbar-height)] items-center justify-between text-ellipsis bg-card shadow-sm",
        className,
      )}
    >
      {children}
    </div>
  )
}
