import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const PlaceholderIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      width={props.size || 30}
      height={props.size || 30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 2V2.01624M8.5 2V2.01624M15 2V2.01624M21.5 2V2.01624M28 2V2.01624M2 27.9838V28M8.5 27.9838V28M15 27.9838V28M21.5 27.9838V28M28 27.9838V28M28 21.4878V21.5041M28 14.9919V15.0081M28 8.49594V8.51218M2 21.4878V21.5041M2 14.9919V15.0081M2 8.49594V8.51218M8.5 8.49594H21.5V21.4878H8.5V8.49594Z"
        stroke="#C294CD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PlaceholderIcon
