import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const PowerIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      fill="currentColor"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.96042 2.62654C10.2318 2.28331 9.97604 1.79164 9.525 1.79164H6.02708C5.93237 1.79095 5.83907 1.81464 5.75616 1.86043C5.67324 1.90622 5.6035 1.97257 5.55365 2.0531L2.90677 6.45727C2.69792 6.80414 2.96042 7.23695 3.37969 7.23695H5.16562L3.48333 11.4375C3.2401 11.9687 3.8974 12.4547 4.33906 12.0703L11.1667 5.60987H7.59948L9.96042 2.62654Z"
        stroke="white"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default PowerIcon
