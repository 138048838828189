import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const ChangePhotoIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.75 7.33333H13.7592M11.4583 19.25H5.5C4.77065 19.25 4.07118 18.9603 3.55546 18.4445C3.03973 17.9288 2.75 17.2293 2.75 16.5V5.5C2.75 4.77065 3.03973 4.07118 3.55546 3.55546C4.07118 3.03973 4.77065 2.75 5.5 2.75H16.5C17.2293 2.75 17.9288 3.03973 18.4445 3.55546C18.9603 4.07118 19.25 4.77065 19.25 5.5V11.4583"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.75 14.6667L7.33333 10.0834C8.184 9.26478 9.23267 9.26478 10.0833 10.0834L13.2917 13.2917"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.8333 12.8333L13.7499 11.9167C14.3723 11.3181 15.1002 11.1568 15.7794 11.4345M17.4166 20.1667V14.6667M17.4166 14.6667L20.1666 17.4167M17.4166 14.6667L14.6666 17.4167"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default ChangePhotoIcon
