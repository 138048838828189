import React from "react"
import { DARK_GREY } from "@/utils/constants/colors-constants"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const BackArrow: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.70703 13.707C7.5195 13.8944 7.26519 13.9998 7.00003 13.9998C6.73487 13.9998 6.48056 13.8944 6.29303 13.707L0.293031 7.70697C0.105559 7.51944 0.000244141 7.26513 0.000244141 6.99997C0.000244141 6.73481 0.105559 6.4805 0.293031 6.29297L6.29303 0.292969C6.48163 0.11081 6.73423 0.0100164 6.99643 0.0122948C7.25863 0.0145731 7.50944 0.119743 7.69485 0.305151C7.88026 0.490559 7.98543 0.741372 7.9877 1.00357C7.98998 1.26577 7.88919 1.51837 7.70703 1.70697L3.41403 5.99997L15 5.99997C15.2652 5.99997 15.5196 6.10533 15.7071 6.29286C15.8947 6.4804 16 6.73475 16 6.99997C16 7.26519 15.8947 7.51954 15.7071 7.70708C15.5196 7.89461 15.2652 7.99997 15 7.99997L3.41403 7.99997L7.70703 12.293C7.8945 12.4805 7.99982 12.7348 7.99982 13C7.99982 13.2651 7.8945 13.5194 7.70703 13.707Z"
        fill={props.color || DARK_GREY}
      />
    </svg>
  )
}

export default BackArrow
