import React, { type ChangeEvent, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { ApiariesIcon } from "@/assets/icons/bees/apiaries-icon.tsx"
import DetailsIcon from "@/assets/icons/bees/details-icon.tsx"
import HiveFrameEmptyIcon from "@/assets/icons/bees/hive-frame-empty-icon.tsx"
import HiveFrameHoneyIcon from "@/assets/icons/bees/hive-frame-honey-icon.tsx"
import HiveFramesBroodIcon from "@/assets/icons/bees/hive-frames-brood-icon.tsx"
import HiveIcon from "@/assets/icons/bees/hive-icon.tsx"
import { HiveManufacturerIcon } from "@/assets/icons/bees/hive-manufacturer-icon.tsx"
import IdentificationNumberIcon from "@/assets/icons/bees/identification-number-icon.tsx"
import NrBeesBeginningIcon from "@/assets/icons/bees/nr-bees-beginning-icon.tsx"
import { HamburgerIcon } from "@/assets/icons/misc/hamburger-icon.tsx"
import { AttachImageFieldBase64 } from "@/components/attach-image-field/attach-image-field-base-64.tsx"
import OptionCheckboxSelector from "@/components/option-checkbox-selector/option-checkbox-selector.tsx"
import {
  DrawerTrigger,
  Drawer,
  DrawerContent,
  DrawerClose,
} from "@/components/shadcn/drawer.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import InputFieldWrapperWithIcon from "@/components/text-card-wrapper-with-icon/input-field-wrapper-with-icon.tsx"
import TextAreaFieldWrapperWithIcon from "@/components/text-card-wrapper-with-icon/text-area-field-wrapper-with-icon.tsx"
import { useGetApiariesQuery } from "@/features/bees/api/apiaries-api.ts"
import {
  type HiveInput,
  type HiveType,
  hiveTypeOptions,
} from "@/features/bees/types/apiaries.ts"
import RequiredIndicator from "@/features/farm/components/required-indicator/required-indicator.tsx"
import { useAppDispatch } from "@/redux/hooks.ts"
import {
  setDraftBeeCount,
  setDraftBroodFrames,
  setDraftEmptyFrames,
  setDraftHiveApiary,
  setDraftHiveCode,
  setDraftHiveDescription,
  setDraftHiveManufacturer,
  setDraftHivePicture,
  setDraftHiveType,
  setDraftHoneyFrames,
} from "@/redux/slices/hive-draft-slice.ts"

interface Props {
  hiveId: string
  draftHive: HiveInput
}

export const HiveEditor: React.FC<Props> = ({ draftHive, hiveId }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { apiaryId } = useParams()
  const { data: apiaries } = useGetApiariesQuery()
  const apiariesOptions =
    apiaries &&
    apiaries.map((apiary) => ({
      value: apiary.id,
      label: apiary.name,
    }))

  useEffect(() => {
    if (apiaryId) {
      dispatch(
        setDraftHiveApiary({
          id: hiveId,
          apiary: apiaryId,
        }),
      )
    }
  }, [apiaryId, dispatch, hiveId])

  const handleHiveCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setDraftHiveCode({ id: hiveId, code: event.target.value }))
  }

  const handleHiveManufacturerChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(
      setDraftHiveManufacturer({
        id: hiveId,
        hive_manufacturer: event.target.value,
      }),
    )
  }

  const handleBroodFramesChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setDraftBroodFrames({
        id: hiveId,
        number_of_brood_frames: event.target.value,
      }),
    )
  }

  const handleHoneyFramesChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setDraftHoneyFrames({
        id: hiveId,
        number_of_honey_frames: event.target.value,
      }),
    )
  }

  const handleEmptyFramesChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setDraftEmptyFrames({
        id: hiveId,
        number_of_empty_frames: event.target.value,
      }),
    )
  }

  const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(
      setDraftHiveDescription({
        id: hiveId,
        description: event.target.value,
      }),
    )
  }

  const handleBeeCountChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setDraftBeeCount({
        id: hiveId,
        bee_count: event.target.value,
      }),
    )
  }

  const handleHiveTypeChange = (hive_type: HiveType) => {
    dispatch(
      setDraftHiveType({
        id: hiveId,
        hive_type,
      }),
    )
  }

  const handlePictureChange = (picture: string | null) => {
    dispatch(
      setDraftHivePicture({
        id: hiveId,
        picture,
      }),
    )
  }

  const handleApiaryChange = (apiaryId: string) => {
    dispatch(
      setDraftHiveApiary({
        id: hiveId,
        apiary: apiaryId,
      }),
    )
  }

  const currentApiarySelected =
    apiaries && apiaries.find((a) => a.id === draftHive.apiary)

  return (
    <div className={"flex flex-col gap-[10px] pb-10"}>
      <InputFieldWrapperWithIcon
        icon={
          <div
            className={
              "grid h-[30px] w-[30px] place-content-center rounded-[4px] bg-yellow-300 text-white"
            }
          >
            <IdentificationNumberIcon className={"scale-150"} />
          </div>
        }
        isRequired
        align={"row"}
        isDisabled={false}
        name={t("hiveCode")}
        onChange={handleHiveCodeChange}
        value={draftHive.code}
      />
      <OverflowMenuItem isDisabled={!!apiaryId}>
        <Drawer>
          <DrawerTrigger>
            <div className={"grid grid-cols-[30px_auto] items-center gap-2"}>
              <div
                className={
                  "grid h-[30px] w-[30px] place-content-center rounded-[4px] bg-blue-300 text-white"
                }
              >
                <ApiariesIcon />
                <RequiredIndicator className={"top-0"} />
              </div>
              <div className={"flex w-full justify-between"}>
                {currentApiarySelected ? (
                  <div className={"text-[14px] text-neutral-700"}>
                    {currentApiarySelected.name}
                  </div>
                ) : (
                  <div className={"text-[14px] text-[var(--grey)]"}>
                    {t("apiary")}
                  </div>
                )}
                <HamburgerIcon className={"scale-150"} />
              </div>
            </div>
          </DrawerTrigger>
          <DrawerContent className={"pb-10"}>
            {apiariesOptions?.map((obj, index) => (
              <DrawerClose key={index} asChild>
                <OptionCheckboxSelector
                  option={obj}
                  selected={obj.value === draftHive.apiary}
                  onClick={() => handleApiaryChange(obj.value)}
                />
              </DrawerClose>
            ))}
          </DrawerContent>
        </Drawer>
      </OverflowMenuItem>
      <OverflowMenuItem>
        <Drawer>
          <DrawerTrigger>
            <div className={"grid grid-cols-[30px_auto] items-center gap-2"}>
              <div
                className={
                  "grid h-[30px] w-[30px] place-content-center rounded-[4px] bg-red-300 text-white"
                }
              >
                <HiveIcon className={"scale-150"} />
              </div>
              <div className={"flex w-full justify-between"}>
                {draftHive.hive_type ? (
                  <div className={"text-[14px] text-neutral-700"}>
                    {t(draftHive.hive_type)}
                  </div>
                ) : (
                  <div className={"text-[14px] text-[var(--grey)]"}>
                    {t("hiveType")}
                  </div>
                )}
                <HamburgerIcon className={"scale-150"} />
                <RequiredIndicator className={"top-0"} />
              </div>
            </div>
          </DrawerTrigger>
          <DrawerContent className={"pb-10"}>
            {hiveTypeOptions.map((obj, index) => (
              <DrawerClose key={index} asChild>
                <OptionCheckboxSelector
                  option={obj}
                  selected={obj.value === draftHive.hive_type}
                  onClick={() => handleHiveTypeChange(obj.value as HiveType)}
                />
              </DrawerClose>
            ))}
          </DrawerContent>
        </Drawer>
      </OverflowMenuItem>
      <InputFieldWrapperWithIcon
        icon={
          <div
            className={
              "grid h-[30px] w-[30px] place-content-center rounded-[4px] bg-green-400 text-white"
            }
          >
            <NrBeesBeginningIcon className={"scale-150 text-green-400"} />
          </div>
        }
        align={"row"}
        inputMode={"numeric"}
        isDisabled={false}
        isRequired
        name={t("beeCount")}
        onChange={handleBeeCountChange}
        value={draftHive.bee_count}
      />
      <InputFieldWrapperWithIcon
        icon={
          <div
            className={
              "grid h-[30px] w-[30px] place-content-center rounded-[4px] bg-yellow-300 text-white"
            }
          >
            <HiveFramesBroodIcon className={"scale-150"} />
          </div>
        }
        align={"row"}
        inputMode={"numeric"}
        isDisabled={false}
        isRequired
        name={t("broodFramesNumber")}
        onChange={handleBroodFramesChange}
        value={draftHive.number_of_brood_frames}
      />
      <InputFieldWrapperWithIcon
        icon={
          <div
            className={
              "grid h-[30px] w-[30px] place-content-center rounded-[4px] bg-orange-300 text-white"
            }
          >
            <HiveFrameHoneyIcon className={"scale-150"} />
          </div>
        }
        align={"row"}
        inputMode={"numeric"}
        isDisabled={false}
        isRequired
        name={t("honeyFramesNumber")}
        onChange={handleHoneyFramesChange}
        value={draftHive.number_of_honey_frames}
      />
      <InputFieldWrapperWithIcon
        icon={
          <div
            className={
              "grid h-[30px] w-[30px] place-content-center rounded-[4px] bg-purple-400 text-white"
            }
          >
            <HiveFrameEmptyIcon className={"scale-150"} />
          </div>
        }
        align={"row"}
        inputMode={"numeric"}
        isDisabled={false}
        isRequired
        name={t("emptyFramesNumber")}
        onChange={handleEmptyFramesChange}
        value={draftHive.number_of_empty_frames}
      />
      <InputFieldWrapperWithIcon
        icon={
          <div
            className={
              "grid h-[30px] w-[30px] place-content-center rounded-[4px] bg-pink-400 text-white"
            }
          >
            <HiveManufacturerIcon />
          </div>
        }
        align={"row"}
        isDisabled={false}
        name={t("hiveManufacturer")}
        onChange={handleHiveManufacturerChange}
        value={draftHive.hive_manufacturer}
      />
      <TextAreaFieldWrapperWithIcon
        icon={
          <div
            className={
              "grid h-[30px] w-[30px] place-content-center rounded-[4px] bg-[var(--menu-financial-journal-color)] text-white"
            }
          >
            <DetailsIcon className={"scale-150"} />
          </div>
        }
        name={t("details")}
        onChange={handleDescriptionChange}
        value={draftHive.description}
        isReadOnly={false}
      />
      <AttachImageFieldBase64
        picture={draftHive.picture}
        onChoosePicture={handlePictureChange}
      />
    </div>
  )
}
