import useFarmPermissions from "@/features/permissions/hooks/use-farm-permissions.ts"

const useFarmHasMammals = () => {
  const { permissions } = useFarmPermissions()

  const isCowEnabled = permissions?.cow_module
  const isGoatEnabled = permissions?.goat_module
  const isPorkEnabled = permissions?.pork_module
  const isSheepEnabled = permissions?.sheep_module

  return isGoatEnabled || isPorkEnabled || isSheepEnabled || isCowEnabled
}

export default useFarmHasMammals
