import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const IdentificationNumberIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.2734 4.27148C11.3379 4.27148 11.3906 4.21875 11.3906 4.1543V3.27539C11.3906 3.21094 11.3379 3.1582 11.2734 3.1582H8.87109V0.726562C8.87109 0.662109 8.81836 0.609375 8.75391 0.609375H7.81641C7.75195 0.609375 7.69922 0.662109 7.69922 0.726562V3.1582H4.35938V0.726562C4.35938 0.662109 4.30664 0.609375 4.24219 0.609375H3.30469C3.24023 0.609375 3.1875 0.662109 3.1875 0.726562V3.1582H0.726562C0.662109 3.1582 0.609375 3.21094 0.609375 3.27539V4.1543C0.609375 4.21875 0.662109 4.27148 0.726562 4.27148H3.1875V7.72852H0.726562C0.662109 7.72852 0.609375 7.78125 0.609375 7.8457V8.72461C0.609375 8.78906 0.662109 8.8418 0.726562 8.8418H3.1875V11.2734C3.1875 11.3379 3.24023 11.3906 3.30469 11.3906H4.24219C4.30664 11.3906 4.35938 11.3379 4.35938 11.2734V8.8418H7.69922V11.2734C7.69922 11.3379 7.75195 11.3906 7.81641 11.3906H8.75391C8.81836 11.3906 8.87109 11.3379 8.87109 11.2734V8.8418H11.2734C11.3379 8.8418 11.3906 8.78906 11.3906 8.72461V7.8457C11.3906 7.78125 11.3379 7.72852 11.2734 7.72852H8.87109V4.27148H11.2734ZM7.69922 7.72852H4.35938V4.27148H7.69922V7.72852Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IdentificationNumberIcon
