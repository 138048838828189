import React from "react"
import { useNavigate } from "react-router-dom"
import { ScrollableHiveList } from "@/features/bees/components/apiary-widget/scrollable-hive-list.tsx"
import { type ApiarySummaryResponse } from "@/features/bees/types/apiaries.ts"
import EmptyContentCreateItemButton from "@/features/home/components/empty-content-create-item-button/empty-content-create-item-button.tsx"
import WidgetHeader from "@/features/home/components/widget-header/widget-header.tsx"
import {
  APIARIES_SCREEN_PATH,
  ALL_HIVES_PATH,
} from "@/utils/constants/routes.ts"

interface Props {
  apiary: ApiarySummaryResponse
}

export const ApiaryWidget: React.FC<Props> = ({ apiary }) => {
  const navigationString = APIARIES_SCREEN_PATH + `/${apiary.id}`
  const newHiveForApiaryNavigationString = `${APIARIES_SCREEN_PATH}/${apiary.id}${ALL_HIVES_PATH}/new/`
  const navigate = useNavigate()

  return (
    <div
      className={
        "widget-border box-border flex w-full flex-col gap-5 bg-white px-4 py-4"
      }
    >
      <WidgetHeader
        navigateTo={navigationString}
        title={apiary.name}
        addItemGoTo={
          apiary.hives.length > 0
            ? () => navigate(newHiveForApiaryNavigationString)
            : undefined
        }
      />
      {apiary.hives.length > 0 ? (
        <ScrollableHiveList hives={apiary.hives} apiaryId={apiary.id} />
      ) : (
        <EmptyContentCreateItemButton
          className={"h-[90px]"}
          navigateTo={newHiveForApiaryNavigationString}
        />
      )}
    </div>
  )
}
