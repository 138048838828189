import React, { forwardRef, useImperativeHandle, useRef } from "react"
import { useScrollRestoration } from "@/hooks/use-scroll-restoration.ts"
import { cn } from "@/utils/styles.ts"

interface PageContentWrapperSmallProps {
  children: React.ReactNode
  className?: string
}

const PageContentWrapperSmall = forwardRef<
  HTMLDivElement,
  PageContentWrapperSmallProps
>(({ children, className }, ref) => {
  const internalRef = useRef<HTMLDivElement>(null)

  useScrollRestoration(internalRef)

  useImperativeHandle(ref, () => internalRef.current!)

  return (
    <div
      ref={ref}
      className={cn(
        "pb-[calc(16px + env(safe-area-inset-bottom))] box-border h-[calc(var(--scrollable-content-small))] overflow-y-auto overflow-x-hidden p-4",
        className,
      )}
    >
      {children}
    </div>
  )
})

export default PageContentWrapperSmall
