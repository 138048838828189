import { type ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { cn } from "@/utils/styles.ts"

interface Props {
  text: string
  value: string
  children: ReactNode
  className?: string
}

const HiveDetailsRow: React.FC<Props> = ({
  text,
  value,
  children,
  className,
}) => {
  const { t } = useTranslation()
  return (
    <div className={cn("flex w-full items-center gap-2", className)}>
      <div className={"flex items-center gap-2"}>
        <div
          className={
            "grid h-[30px] w-[30px] place-content-center rounded-[4px] bg-brown-500 text-white"
          }
        >
          {children}
        </div>
        <p className={"text-gray-500"}>{text}</p>
      </div>
      {value && <p>{t(value)}</p>}
    </div>
  )
}

export default HiveDetailsRow
