import isEqual from "lodash.isequal"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import {
  type HiveInput,
  hiveInputInitialState,
} from "@/features/bees/types/apiaries.ts"
import { useAppSelector } from "@/redux/hooks.ts"
import { resetHiveDraft } from "@/redux/slices/hive-draft-slice.ts"
import { NEW_ENTITY } from "@/utils/types/misc-types.ts"

export const useInitializeHiveDraft = () => {
  const dispatch = useDispatch()
  const hiveDraft = useAppSelector(
    (state) => state.hiveDraftSlice.drafts[NEW_ENTITY],
  )
  const { apiaryId } = useParams()

  const baselineHiveInput: HiveInput = {
    ...hiveInputInitialState,
    apiary: apiaryId ? apiaryId : hiveInputInitialState.apiary,
  }

  const hasChanges = hiveDraft && !isEqual(baselineHiveInput, hiveDraft)

  if (!hiveDraft) {
    dispatch(resetHiveDraft({ id: NEW_ENTITY }))
  }

  return { hiveDraft, hasChanges }
}
