import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import { type Animal, getStatusesForAnimal } from "@/features/farm"
import { setAnimalsFilter } from "@/redux/slices/animals-filter-slice.ts"

const ANIMALS_BASE_URL = "animals/"

export const animalsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAnimals: builder.query<Animal[], void>({
      query: () => ANIMALS_BASE_URL,
      transformResponse: (response: Animal[]) => {
        return response.map((animal) => ({
          ...animal,
          statuses: getStatusesForAnimal(animal),
        }))
      },
      providesTags: (result) => {
        if (!result) {
          return [{ type: TagType.Animals, id: "LIST" }]
        }

        const animalTags = result.map(({ id }) => ({
          type: TagType.Animals,
          id: id ?? "LIST",
        }))

        return [...animalTags, { type: TagType.Animals, id: "LIST" }]
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setAnimalsFilter(data))
        } catch (err) {
          console.error("Failed to fetch animals:", err)
        }
      },
    }),
    getAnimalById: builder.query<Animal, string>({
      query: (id) => ANIMALS_BASE_URL + `${id}/`,
      transformResponse: (response: Animal) => {
        return {
          ...response,
          statuses: getStatusesForAnimal(response),
        }
      },
      providesTags: (_result, _error, id) => [{ type: TagType.Animals, id }],
    }),
    createAnimal: builder.mutation<Animal, Partial<Animal>>({
      query: (newAnimal) => ({
        url: ANIMALS_BASE_URL,
        method: "POST",
        body: newAnimal,
      }),
      invalidatesTags: [{ type: TagType.Animals }],
    }),
    updateAnimal: builder.mutation<Animal, Partial<Animal>>({
      query: (updatedAnimal) => ({
        url: ANIMALS_BASE_URL + `${updatedAnimal.id}/`,
        method: "PATCH",
        body: updatedAnimal,
      }),
      invalidatesTags: [{ type: TagType.Animals }],
    }),
    deleteAnimal: builder.mutation<Animal, string>({
      query: (id) => ({
        url: ANIMALS_BASE_URL + `${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [
        { type: TagType.Animals },
        { type: TagType.GestationCalendars },
      ],
    }),
  }),
})

export const {
  useGetAnimalsQuery,
  useGetAnimalByIdQuery,
  useLazyGetAnimalByIdQuery,
  useCreateAnimalMutation,
  useUpdateAnimalMutation,
  useDeleteAnimalMutation,
} = animalsApi
