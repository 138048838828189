import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import NoResultsFound from "@/components/not-found/no-results-found.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import { HiveCard } from "@/features/bees/components/hive-card/hive-card.tsx"
import { HivesFilterTab } from "@/features/bees/components/hives-filter-tab/hives-filter-tab.tsx"
import { useHivesFilterURL } from "@/features/bees/hooks/use-hives-filter-URL.ts"
import { type HiveResponse } from "@/features/bees/types/apiaries.ts"
import { useAppSelector } from "@/redux/hooks.ts"
import { HOME_SCREEN_PATH, NEW_HIVE } from "@/utils/constants/routes.ts"

const AllHives = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [filteredHives, setFilteredHives] = useState<HiveResponse[]>([])
  const {
    selections,
    hives,
    isLoading,
    handleApiaryChange,
    handleTypeChange,
    handleSearchQueryChange,
  } = useHivesFilterURL()
  const hiveDrafts = useAppSelector((state) => state.hiveDraftSlice.drafts)

  useEffect(() => {
    const hivesWithChanges = hives.map((hive) => {
      const draft = hiveDrafts[hive.id]
      return draft ? { ...hive, hasChanges: true } : hive
    })
    setFilteredHives(hivesWithChanges)
  }, [hiveDrafts, hives])

  return (
    <>
      <MobileTopBar
        title={t("myHives")}
        navigateTo={NEW_HIVE}
        onBackClick={() => navigate(HOME_SCREEN_PATH)}
      />
      <PageContentWrapperLarge className={"flex flex-col gap-4 p-4"}>
        <HivesFilterTab
          handleApiaryChange={handleApiaryChange}
          handleSearchQueryChange={handleSearchQueryChange}
          handleTypeChange={handleTypeChange}
          selections={selections}
        />
        {isLoading && <LoadingAnimation customStyle={{ height: "inherit" }} />}
        {!isLoading &&
          filteredHives?.map((hive, index) => (
            <HiveCard hive={hive} key={index} />
          ))}
        {!isLoading && filteredHives.length === 0 && (
          <NoResultsFound
            image={"hive"}
            message={t("noHivesFoundForGivenQuery")}
          />
        )}
      </PageContentWrapperLarge>
    </>
  )
}

export default AllHives
