import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { apiSlice } from "@/features/api/apiSlice"

type AuthState = {
  token: string | null
}

const initialState: AuthState = {
  token: null,
}

export const logout = createAsyncThunk("auth/logout", (_, { dispatch }) => {
  dispatch(apiSlice.util.resetApiState())
  localStorage.clear()
})

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, () => initialState)
  },
})

export default authSlice.reducer

export const { setToken } = authSlice.actions
