import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

export const HamburgerIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 13H14M6 10H14M6 7H14"
        stroke="#414141"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
