import { isBefore, isSameDay } from "date-fns"
import styles from "@/features/tasks/components/task-card/task-card.module.css"
import { type TaskFilterSelections } from "@/features/tasks/hooks/use-filter-tasks.ts"
import { type Task } from "@/features/tasks/types/task.ts"
import {
  RecurrenceConstants,
  ReminderConstants,
} from "@/utils/constants/time-constants.ts"

export enum TASK_STATES {
  DONE = "DONE",
  TODO = "TODO",
  PAST = "PAST",
  SELECTED = "SELECTED",
}

export interface TaskClassification {
  dueTasks: Task[]
  currentTasks: Task[]
  futureTasks: Task[]
  doneTasks: Task[]
}

export const taskClassificationCheckNotAllEmpty = (
  tasks: TaskClassification,
): boolean => {
  return Object.values(tasks).some((taskList: Task[]) => taskList.length > 0)
}

export function groupTasksByDate(tasks: Task[]): TaskClassification {
  const currentTasks: Task[] = []
  const dueTasks: Task[] = []
  const futureTasks: Task[] = []
  const doneTasks: Task[] = []

  const currentDate = new Date()

  tasks.forEach((task) => {
    if (task.is_done) {
      doneTasks.push(task)
      return
    }

    if (
      task.due_date === null ||
      task.due_date === "" ||
      task.due_date === undefined
    ) {
      futureTasks.push(task)
      return
    }

    const taskDate = new Date(task.due_date)

    if (isSameDay(taskDate, currentDate)) {
      if (isBefore(taskDate, currentDate)) {
        dueTasks.push(task)
      } else {
        currentTasks.push(task)
      }
    } else if (isBefore(taskDate, currentDate)) {
      dueTasks.push(task)
    } else {
      futureTasks.push(task)
    }
  })

  return { currentTasks, dueTasks, futureTasks, doneTasks }
}

export const getTaskState = (task: Task, isSelected: boolean): string => {
  if (isSelected) {
    return TASK_STATES.SELECTED
  }

  if (task.is_done) {
    return TASK_STATES.DONE
  }

  if (
    task.due_date === null ||
    task.due_date === "" ||
    task.due_date === undefined
  ) {
    return TASK_STATES.TODO
  }

  const taskDate = new Date(task.due_date)
  const currentDate = new Date()

  if (isSameDay(taskDate, currentDate)) {
    return isBefore(taskDate, currentDate) ? TASK_STATES.PAST : TASK_STATES.TODO
  }

  return isBefore(taskDate, currentDate) ? TASK_STATES.PAST : TASK_STATES.TODO
}

export const getTaskCardStyle = (taskState: string): string => {
  switch (taskState) {
    case TASK_STATES.SELECTED:
      return styles["task-card-selected"]
    case TASK_STATES.DONE:
      return styles["task-card"]
    case TASK_STATES.TODO:
      return styles["task-card"]
    case TASK_STATES.PAST:
      return styles["task-card-past"]
    default:
      return styles["task-card"]
  }
}

export function filterAndSortTasks(
  tasks: Task[],
  searchQuery: string,
  selections: TaskFilterSelections,
): Task[] {
  return tasks.filter((task) => {
    const taskString = (task.description + task.title).toLowerCase()
    const matchesSearch = taskString.includes(searchQuery.toLowerCase())
    const matchesStatus =
      selections.is_done === -1 || task.is_done === selections.is_done
    const matchesCategory =
      selections.category === "" || task.category === selections.category
    const matchesAnimal =
      selections.animals.length === 0 ||
      (task.animals &&
        task.animals.some((animal) => selections.animals.includes(animal)))
    return matchesSearch && matchesStatus && matchesCategory && matchesAnimal
  })
}

export interface TaskFilterSelection {
  category: string
  is_done: boolean
  animals: string[]
}

export const initialTaskFilterSelections: TaskFilterSelection = {
  category: "",
  is_done: false,
  animals: [],
}

export const getReminderLabel = (value: ReminderConstants): string => {
  switch (value) {
    case ReminderConstants.THIRTY_MINUTES:
      return "before30"
    case ReminderConstants.ONE_HOUR:
      return "beforeHour"
    case ReminderConstants.ONE_DAY:
      return "beforeDay"
    case ReminderConstants.ONE_WEEK:
      return "beforeWeek"
    default:
      return ""
  }
}

export const getRecurrenceLabel = (value: RecurrenceConstants): string => {
  switch (value) {
    case RecurrenceConstants.DAILY:
      return "daily"
    case RecurrenceConstants.WEEKLY:
      return "weekly"
    case RecurrenceConstants.MONTHLY:
      return "monthly"
    case RecurrenceConstants.YEARLY:
      return "yearly"
    default:
      return ""
  }
}
