import isEqual from "lodash.isequal"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { type QueenInput } from "@/features/bees/types/apiaries.ts"
import { useAppSelector } from "@/redux/hooks.ts"
import {
  queenInputInitialState,
  resetQueenDraft,
} from "@/redux/slices/queen-draft-slice.ts"
import { NEW_ENTITY } from "@/utils/types/misc-types.ts"

export const useInitializeQueenDraft = () => {
  const dispatch = useDispatch()
  const queenDraft = useAppSelector(
    (state) => state.queenDraftSlice.drafts[NEW_ENTITY],
  )
  const { hiveId } = useParams()

  const baselineQueenInput: QueenInput = {
    ...queenInputInitialState,
    hive: hiveId ? hiveId : queenInputInitialState.hive,
  }

  const hasChanges = queenDraft && !isEqual(baselineQueenInput, queenDraft)

  if (!queenDraft) {
    dispatch(resetQueenDraft({ id: NEW_ENTITY }))
  }

  return { queenDraft, hasChanges }
}
