export interface Apiary {
  id: string
  name: string
  address: string
  registration_code: string
  latitude: string
  longitude: string
  description: string
  picture: string | null
  created_at: string
  updated_at: string
  farm: string
  tasks: string[]
  notes: string[]
}

export type ApiaryInput = Omit<
  Apiary,
  | "id"
  | "created_at"
  | "updated_at"
  | "tasks"
  | "notes"
  | "farm"
  | "latitude"
  | "longitude"
>

export const apiaryInputInitialState: ApiaryInput = {
  registration_code: "",
  name: "",
  description: "",
  address: "",
  picture: "",
}

export interface ApiarySummaryResponse {
  id: string
  name: string
  address: string
  hives: HiveSummary[]
}

export interface ApiaryResponse extends Apiary {
  hives: HiveResponse[]
}

export enum HiveType {
  Langstroth = "Langstroth",
  Warre = "Warre",
  TopBar = "Top Bar",
  Other = "Other",
}

export const hiveTypeOptions = Object.entries(HiveType).map(([_, value]) => ({
  value: value,
  label: value,
}))

export interface Hive {
  id: string
  apiary: string
  code: string
  hive_type: HiveType
  hive_manufacturer: string
  number_of_brood_frames: string
  number_of_honey_frames: string
  number_of_empty_frames: string
  bee_count: string
  description: string
  picture: string | null
  created_at: string
  updated_at: string
}

export type HiveInput = Omit<Hive, "id" | "created_at" | "updated_at">

export const hiveInputInitialState: HiveInput = {
  apiary: "",
  code: "",
  hive_type: "" as HiveType,
  hive_manufacturer: "",
  number_of_empty_frames: "",
  number_of_honey_frames: "",
  number_of_brood_frames: "",
  description: "",
  picture: "",
  bee_count: "",
}

export interface HiveResponse extends Hive {
  queen: QueenSummary
  tasks: string[]
  notes: string[]
}

export type HiveSummary = Pick<Hive, "hive_type" | "id" | "code">

export enum QueenOrigins {
  Purchased = "Purchased",
  OwnProduction = "Own Production",
}

export const queenOriginOptions = Object.entries(QueenOrigins).map(
  ([_, value]) => ({
    value: value,
    label: value,
  }),
)

export enum QueenColors {
  Blue = "Blue",
  White = "White",
  Yellow = "Yellow",
  Red = "Red",
  Green = "Green",
}

export interface Queen {
  id: string
  name: string
  year?: string
  hive: string
  apiary: string
  color?: QueenColors
  origin: QueenOrigins
  description: string
  picture: string | null
  notes: string[]
  tasks: string[]
  created_at: string
  updated_at: string
}

export type QueenInput = Omit<Queen, "id" | "created_at" | "updated_at">

export interface QueenResponse extends Queen {
  hive: string
  apiary: string
  tasks: string[]
  notes: string[]
}

export type QueenSummary = Pick<Queen, "id" | "name" | "year" | "color">
