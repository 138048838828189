import { useCallback } from "react"

interface GenericPictureProps {
  src: string
  alt: string
  width?: number
  height?: number
  size?: number
  borderRadius?: number
  shadow?: boolean
  contain?: boolean
  style?: React.CSSProperties
  onImageLoad?: () => void
}

/**
 *  Generic Picture component
 *
 * @param src - image source as string
 * @param alt - image alt as string
 * @param width - image width as number
 * @param size - image size as number applies to both width and height
 * @param height - image height as number
 * @param borderRadius - image border radius as number default is 8
 * @param shadow - image shadow as boolean default is false
 * @param contain - image object fit as boolean default is false
 * @param style - additional custom styles as React.CSSProperties
 * @param onImageLoad
 * @constructor
 */
const GenericPicture: React.FC<GenericPictureProps> = ({
  src,
  alt,
  width,
  size,
  height,
  borderRadius,
  shadow,
  contain,
  style,
  onImageLoad,
}) => {
  const handleImageLoadAction = useCallback(() => {
    if (onImageLoad) {
      onImageLoad()
    }
  }, [onImageLoad])

  return (
    <img
      style={{
        width: width || size,
        height: height || size,
        borderRadius: borderRadius,
        filter: shadow ? "drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.1))" : "",
        objectFit: contain ? "contain" : "cover",
        ...style,
      }}
      onLoad={handleImageLoadAction}
      src={src}
      alt={alt}
    />
  )
}

export default GenericPicture
