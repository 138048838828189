import { type Note } from "@/features/notes"

export const stripHtmlAndTruncate = (
  htmlContent: string,
  maxLength: number = 30,
) => {
  const strippedString = htmlContent.replace(/<[^>]*>?/gm, "")
  return strippedString.length > maxLength
    ? strippedString.substring(0, maxLength) + "..."
    : strippedString
}

export const extractNoteContentPlainText = (
  note: Note,
  length: number = 40,
) => {
  let content = note.content
  const matches = content.match(/<p>(.*?)<\/p>/g)
  if (matches?.length === 1)
    return stripHtmlAndTruncate(content.replace(/<[^>]*>?/gm, ""), length)

  if (!matches) return ""
  for (let i = 1; i < matches.length; i++) {
    const matchResult = matches[i].match(/<p>(.*?)<\/p>/)
    const strippedString = matchResult
      ? matchResult[1].replace(/<[^>]*>?/gm, "")
      : ""
    if (matchResult && strippedString.trim() !== "") {
      content = matchResult[1]
      break
    }
  }

  return stripHtmlAndTruncate(content, length)
}
