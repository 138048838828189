import React from "react"
import { useNavigate } from "react-router-dom"
import { type HiveSummary } from "../../types/apiaries"
import { getImageForHive } from "@/features/bees/utils/apiaries-utils.ts"
import {
  ALL_HIVES_PATH,
  APIARIES_SCREEN_PATH,
} from "@/utils/constants/routes.ts"

interface Props {
  hive: HiveSummary
  apiaryId: string
}

export const HiveCard: React.FC<Props> = ({ hive, apiaryId }) => {
  const navigate = useNavigate()
  const navigateToHive = (hiveId: string) => {
    navigate(`${APIARIES_SCREEN_PATH}/${apiaryId}${ALL_HIVES_PATH}/${hiveId}/`)
  }

  return (
    <div
      className={
        "bg-hive relative h-[90px] w-[90px] overflow-hidden rounded-[8px] p-2"
      }
      onClick={() => navigateToHive(hive.id)}
    >
      <p className={"font-semibold text-neutral-700"}>{hive.code}</p>
      <img
        src={getImageForHive(hive)}
        alt=""
        className={"absolute bottom-[-5px] right-[-5px]"}
      />
    </div>
  )
}
