import React, { type ChangeEvent, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { ColorPalleteIcon } from "@/assets/icons/bees/color-pallete-icon.tsx"
import DetailsIcon from "@/assets/icons/bees/details-icon.tsx"
import HiveIcon from "@/assets/icons/bees/hive-icon.tsx"
import { MonthCalendarIcon } from "@/assets/icons/bees/month-calendar-icon.tsx"
import QueenIcon from "@/assets/icons/bees/queen-icon.tsx"
import { TagIcon } from "@/assets/icons/bees/tag-icon.tsx"
import { HamburgerIcon } from "@/assets/icons/misc/hamburger-icon.tsx"
import { AttachImageFieldBase64 } from "@/components/attach-image-field/attach-image-field-base-64.tsx"
import OptionCheckboxSelector from "@/components/option-checkbox-selector/option-checkbox-selector.tsx"
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from "@/components/shadcn/drawer.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import InputFieldWrapperWithIcon from "@/components/text-card-wrapper-with-icon/input-field-wrapper-with-icon.tsx"
import TextAreaFieldWrapperWithIcon from "@/components/text-card-wrapper-with-icon/text-area-field-wrapper-with-icon.tsx"
import { useGetAllHivesForFarmQuery } from "@/features/bees/api/apiaries-api.ts"
import {
  QueenColors,
  type QueenInput,
  queenOriginOptions,
} from "@/features/bees/types/apiaries.ts"
import RequiredIndicator from "@/features/farm/components/required-indicator/required-indicator.tsx"
import { useAppDispatch } from "@/redux/hooks.ts"
import {
  setDraftQueenColor,
  setDraftQueenDescription,
  setDraftQueenName,
  setDraftQueenOrigin,
  setDraftQueenPicture,
  setDraftQueenYear,
  setQueenDraftHive,
} from "@/redux/slices/queen-draft-slice.ts"
import { cn } from "@/utils/styles.ts"

interface Props {
  queenId: string
  draftQueen: QueenInput
}

export const QueenEditor: React.FC<Props> = ({ queenId, draftQueen }) => {
  const { t } = useTranslation()
  const { hiveId } = useParams()
  const dispatch = useAppDispatch()
  const { data: hives } = useGetAllHivesForFarmQuery()
  const hiveOptions =
    hives?.map((hive) => ({
      label: hive.code,
      value: hive.id,
    })) || []

  const currentHive = hives?.find((hive) => hive.id === hiveId)

  useEffect(() => {
    if (hiveId) {
      dispatch(
        setQueenDraftHive({
          id: queenId,
          hive: hiveId,
        }),
      )
    }
  }, [dispatch, hiveId, queenId])

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setDraftQueenName({
        id: queenId,
        name: event.target.value,
      }),
    )
  }

  const queenColorBackground = () => {
    switch (draftQueen.color) {
      case QueenColors.Blue:
        return "bg-blue-500"
      case QueenColors.White:
        return "bg-[var(--grey)]"
      case QueenColors.Yellow:
        return "bg-yellow-300"
      case QueenColors.Red:
        return "bg-red-500"
      case QueenColors.Green:
        return "bg-green-500"
      default:
        return "bg-brown-500"
    }
  }

  const handleYearChange = (event: ChangeEvent<HTMLInputElement>) => {
    const year = event.target.value
    let color: QueenColors

    switch (Number(year) % 10) {
      case 0:
      case 5:
        color = QueenColors.Blue
        break
      case 1:
      case 6:
        color = QueenColors.White
        break
      case 2:
      case 7:
        color = QueenColors.Yellow
        break
      case 3:
      case 8:
        color = QueenColors.Red
        break
      case 4:
      case 9:
        color = QueenColors.Green
        break
      default:
        color = "" as QueenColors
        break
    }

    if (!year) {
      color = "" as QueenColors
    }

    dispatch(
      setDraftQueenYear({
        id: queenId,
        year,
      }),
    )
    dispatch(
      setDraftQueenColor({
        id: queenId,
        color,
      }),
    )
  }

  const handleOriginChange = (origin: string) => {
    dispatch(
      setDraftQueenOrigin({
        id: queenId,
        origin,
      }),
    )
  }

  const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(
      setDraftQueenDescription({
        id: queenId,
        description: event.target.value,
      }),
    )
  }

  const handlePictureChange = (picture: string | null) => {
    dispatch(
      setDraftQueenPicture({
        id: queenId,
        picture,
      }),
    )
  }

  const handleHiveChange = (hive: string) => {
    dispatch(
      setQueenDraftHive({
        id: queenId,
        hive,
      }),
    )
  }

  return (
    <div className={"flex flex-col gap-[10px] pb-10"}>
      <InputFieldWrapperWithIcon
        icon={
          <div
            className={
              "grid h-[30px] w-[30px] place-content-center rounded-[4px] bg-yellow-300 text-transparent"
            }
          >
            <TagIcon />
          </div>
        }
        align={"row"}
        isDisabled={false}
        name={t("name")}
        isRequired
        onChange={handleNameChange}
        value={draftQueen.name}
      />
      <OverflowMenuItem isDisabled={!!hiveId}>
        <Drawer>
          <DrawerTrigger>
            <div className={"grid grid-cols-[30px_auto] items-center gap-2"}>
              <div
                className={
                  "grid h-[30px] w-[30px] place-content-center rounded-[4px] bg-blue-300 text-white"
                }
              >
                <HiveIcon className={"scale-150"} />
                <RequiredIndicator className={"top-0"} />
              </div>
              <div className={"flex w-full justify-between"}>
                {currentHive ? (
                  <span className={"text-[14px]"}>{currentHive.code}</span>
                ) : (
                  <span className={"text-[14px] text-[var(--grey)]"}>
                    {t("hive")}
                  </span>
                )}
                <HamburgerIcon className={"scale-150"} />
              </div>
            </div>
          </DrawerTrigger>
          <DrawerContent>
            {hiveOptions.map((option, index) => (
              <DrawerClose key={index}>
                <OptionCheckboxSelector
                  option={option}
                  selected={option.value === draftQueen.hive}
                  onClick={() => handleHiveChange(option.value)}
                />
              </DrawerClose>
            ))}
          </DrawerContent>
        </Drawer>
      </OverflowMenuItem>
      <OverflowMenuItem>
        <Drawer>
          <DrawerTrigger>
            <div className={"grid grid-cols-[30px_auto] items-center gap-2"}>
              <div
                className={
                  "grid h-[30px] w-[30px] place-content-center rounded-[4px] bg-red-300 text-transparent"
                }
              >
                <QueenIcon className={"scale-150"} />
                <RequiredIndicator className={"top-0"} />
              </div>
              <div className={"flex w-full justify-between"}>
                {draftQueen.origin ? (
                  <span className={"text-[14px]"}>{t(draftQueen.origin)}</span>
                ) : (
                  <span className={"text-[14px] text-[var(--grey)]"}>
                    {t("queenOrigin")}
                  </span>
                )}
                <HamburgerIcon className={"scale-150"} />
              </div>
            </div>
          </DrawerTrigger>
          <DrawerContent className={"pb-10"}>
            {queenOriginOptions.map((option, index) => (
              <DrawerClose key={index}>
                <OptionCheckboxSelector
                  option={option}
                  selected={option.value === draftQueen.origin}
                  onClick={() => handleOriginChange(option.value)}
                />
              </DrawerClose>
            ))}
          </DrawerContent>
        </Drawer>
      </OverflowMenuItem>
      <InputFieldWrapperWithIcon
        icon={
          <div
            className={
              "grid h-[30px] w-[30px] place-content-center rounded-[4px] bg-green-500 text-transparent"
            }
          >
            <MonthCalendarIcon />
          </div>
        }
        align={"row"}
        inputMode={"numeric"}
        isDisabled={false}
        name={t("yearOfBirth")}
        onChange={handleYearChange}
        value={draftQueen.year}
      />
      <OverflowMenuItem>
        <div className={"flex items-center gap-2"}>
          <div
            className={cn(
              "grid h-[30px] w-[30px] place-content-center rounded-[4px] text-transparent",
              queenColorBackground(),
            )}
          >
            <ColorPalleteIcon />
          </div>
          {draftQueen.color ? (
            <span className={"text-[14px]"}>{t(draftQueen.color)}</span>
          ) : (
            <span className={"text-[14px] text-[var(--grey)]"}>
              {t("queenColor")}
            </span>
          )}
        </div>
      </OverflowMenuItem>
      <TextAreaFieldWrapperWithIcon
        icon={
          <div
            className={
              "grid h-[30px] w-[30px] place-content-center rounded-[4px] bg-[var(--menu-financial-journal-color)] text-white"
            }
          >
            <DetailsIcon className={"scale-150"} />
          </div>
        }
        name={t("details")}
        onChange={handleDescriptionChange}
        value={draftQueen.description}
        isReadOnly={false}
      />
      <AttachImageFieldBase64
        picture={draftQueen.picture}
        onChoosePicture={handlePictureChange}
      />
    </div>
  )
}
