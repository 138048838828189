import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

export const TownAddressIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4448 14.2327C11.4448 13.4065 11.4448 12.9943 11.6334 12.6361C11.8229 12.2788 12.175 12.025 12.8792 11.5183L13.3438 11.1835C14.44 10.3942 14.9882 10 15.6255 10C16.2628 10 16.8109 10.3942 17.9072 11.1835L18.3717 11.5183C19.0759 12.025 19.428 12.2788 19.6166 12.6361C19.8052 12.9934 19.8061 13.4065 19.8061 14.2327V15.5602C19.8061 17.182 19.8061 17.9929 19.2617 18.496C18.7173 18.9991 17.8421 19 16.09 19H11.4448V14.2327Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.0901 10V3.7C16.0901 1.4662 15.6089 1 13.303 1H9.5869C7.28104 1 6.7998 1.4662 6.7998 3.7V19"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.61913 13.6C3.38877 13.6 4.01268 12.7941 4.01268 11.8C4.01268 10.8059 3.38877 10 2.61913 10C1.8495 10 1.22559 10.8059 1.22559 11.8C1.22559 12.7941 1.8495 13.6 2.61913 13.6Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.61913 13.6001V19.0001M1.22559 19.0001H17.9482M10.5159 4.6001H12.374M10.5159 7.3001H12.374M15.6256 19.0001V17.2001"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
