import QueenIcon from "@/assets/icons/bees/queen-icon.tsx"

interface Props {
  queens: string[]
}

export const TaskCardPillQueen: React.FC<Props> = ({ queens }) => {
  return (
    <div
      className={
        "box-border grid h-[30px] w-fit max-w-full grid-cols-[1fr_25px] items-center rounded-[15px] bg-[var(--background-color)] pl-2.5 pr-[5px]"
      }
    >
      <div className={"mx-1 min-w-4 truncate text-[12px]"}>{queens.length}</div>
      <QueenIcon className={"scale-125 text-neutral-500"} />
    </div>
  )
}
