import * as Sentry from "@sentry/react"
import React, { Suspense, useEffect, lazy } from "react"
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  Navigate,
  Outlet,
  ScrollRestoration,
  useLocation,
  useNavigationType,
} from "react-router-dom"
import ErrorBoundary from "@/components/error-boundary/error-boundary.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import LandscapeBlock from "@/components/under-construction/landscape-block.tsx"
import WideScreenMessage from "@/components/under-construction/no-desktop-screen"
import { sentryConfig } from "@/config/sentry/sentry-config.ts"
import AllApiariesTab from "@/features/bees/routes/apiaries/all-apiaries-tab/all-apiaries-tab.tsx"
import ApiaryView from "@/features/bees/routes/apiaries/apiary-view/apiary-view.tsx"
import { EditApiaryView } from "@/features/bees/routes/apiaries/edit-apiary-view/edit-apiary-view.tsx"
import NewApiaryView from "@/features/bees/routes/apiaries/new-apiary-view/new-apiary-view.tsx"
import AllHives from "@/features/bees/routes/hives/all-hives/all-hives.tsx"
import { EditHiveView } from "@/features/bees/routes/hives/edit-hive-view/edit-hive-view.tsx"
import HiveView from "@/features/bees/routes/hives/hive-view/hive-view.tsx"
import NewHiveView from "@/features/bees/routes/hives/new-hive-view/new-hive-view.tsx"
import { EditQueenView } from "@/features/bees/routes/queens/edit-queen-view/edit-queen-view.tsx"
import { NewQueenView } from "@/features/bees/routes/queens/new-queen-view/new-queen-view.tsx"
import { QueenView } from "@/features/bees/routes/queens/queen-view/queen-view.tsx"
import useFarmHasMammals from "@/features/farm/hooks/use-farm-has-mammals.ts"
import NotFoundPage from "@/features/home/routes/not-found-page/not-found-page.tsx"
import ModalPortal from "@/features/modals/modal-portal/modal-portal"
import useFarmPermissions from "@/features/permissions/hooks/use-farm-permissions.ts"
import LoginView from "@/features/settings-account/routes/login-view/login-view.tsx"
import LostPassword from "@/features/settings-account/routes/login-view/lost-password.tsx"
import { useAuth } from "@/hooks/use-auth.ts"
import NoMenuLayout from "@/layouts/no-menu-layout/no-menu-layout.tsx"
import {
  ACCOUNT_SCREEN_PATH,
  AI_ASSISTANT_SCREEN_PATH,
  ALL_GESTATIONAL_CALCULATOR_SCREEN_PATH,
  ANIMAL_EVENT_SCREEN_PATH,
  ANIMAL_PROFILE_PATH,
  ANIMAL_SCREEN_PATH,
  HIVE_VIEW,
  APIARY_VIEW,
  CONFIDENTIALITY_PATH,
  CONTACT_PATH,
  EDIT_APIARY_SCREEN_PATH,
  EDIT_FARM_PROFILE_PATH,
  EDIT_MY_PROFILE_PATH,
  FAQ_PATH,
  FARM_SCREEN_PATH,
  FINANCE_TRANSACTIONS_HISTORY_SCREEN_PATH,
  FINANCE_TRANSACTIONS_SCREEN_PATH,
  FINANCIAL_SCREEN_PATH,
  GESTATIONAL_CALCULATOR_SCREEN_PATH,
  ALL_HIVES_PATH,
  LOGIN_SCREEN_PATH,
  LOST_PASSWORD_PATH,
  NEW_ANIMAL_EVENT_SCREEN_PATH,
  NEW_ANIMAL_SCREEN_PATH,
  NEW_APIARY_SCREEN_PATH,
  NEW_GESTATIONAL_CALCULATOR_SCREEN_PATH,
  NEW_HIVE_FOR_APIARY,
  NEW_NOTE_SCREEN_PATH,
  NEW_TASK_SCREEN_PATH,
  NEW_TRANSACTION_SCREEN_PATH,
  NOT_ENABLED,
  NOTE_SCREEN_PATH,
  NOTES_VIEW_PATH,
  NOTIFICATION_PREFERENCES_PATH,
  NOTIFICATIONS_PATH,
  RESET_PASSWORD_PATH,
  SETTINGS_SCREEN_PATH,
  TASKS_SCREEN_PATH,
  TERMS_PATH,
  TUTORIAL_PATH,
  VIEW_COMPANY_INFO_PATH,
  APIARY_HIVE_QUEEN,
  EDIT_QUEEN_VIEW,
  NEW_QUEEN_FOR_HIVE,
  EDIT_HIVE_VIEW,
  APIARIES_SCREEN_PATH,
  NEW_HIVE,
} from "@/utils/constants/routes.ts"
import ToastConfig from "@/utils/toast-config/toasts-config"

// Lazy routes
const MenuPage = React.lazy(
  () => import("@/features/menu/routes/menu-page/menu-page.tsx"),
)
const NewNoteView = React.lazy(
  () => import("@/features/notes/routes/new-note-view/new-note-view"),
)
const NoteEditorView = React.lazy(
  () => import("@/features/notes/routes/note-editor-view/note-editor-view.tsx"),
)
const NotesLayout = React.lazy(
  () => import("@/features/notes/routes/notes-layout/notes-layout"),
)
const NotificationView = React.lazy(
  () =>
    import(
      "@/features/notifications/routes/notification-view/notification-view"
    ),
)
const NotificationsPage = React.lazy(
  () =>
    import(
      "@/features/notifications/routes/notifications-page/notifications-page"
    ),
)
const AccountTab = React.lazy(
  () =>
    import("@/features/settings-account/routes/account-tab/account-tab.tsx"),
)
const CompanyInfoPage = React.lazy(
  () => import("@/features/settings-account/routes/company-info/company-info"),
)
const FarmProfilePage = React.lazy(
  () => import("@/features/settings-account/routes/farm-profile/farm-profile"),
)
const NotEnabled = React.lazy(
  () => import("@/features/settings-account/routes/not-purchased/not-enabled"),
)
const NotificationPreferences = React.lazy(
  () =>
    import(
      "@/features/settings-account/routes/notification-preferences/notification-preferences"
    ),
)
const SettingsConfidentiality = React.lazy(
  () =>
    import(
      "@/features/settings-account/routes/settings-confidentiality/settings-confidentiality"
    ),
)
const SettingsContact = React.lazy(
  () =>
    import(
      "@/features/settings-account/routes/settings-contact/settings-contact"
    ),
)
const SettingsFaq = React.lazy(
  () => import("@/features/settings-account/routes/settings-faq/settings-faq"),
)
const SettingsResetPassword = React.lazy(
  () =>
    import(
      "@/features/settings-account/routes/settings-reset-password/settings-reset-password"
    ),
)
const SettingsTerms = React.lazy(
  () =>
    import("@/features/settings-account/routes/settings-terms/settings-terms"),
)
const SettingsTutorial = React.lazy(
  () =>
    import(
      "@/features/settings-account/routes/settings-tutorial/settings-tutorial"
    ),
)
const UserProfileWrapper = React.lazy(
  () =>
    import(
      "@/features/settings-account/routes/user-profile/user-profile-wrapper"
    ),
)
const NewTaskView = React.lazy(
  () => import("@/features/tasks/routes/new-task-view/new-task-view"),
)
const TaskLayout = React.lazy(
  () => import("@/features/tasks/routes/task-layout/task-layout"),
)
const TaskView = React.lazy(
  () => import("@/features/tasks/routes/task-view/task-view.tsx"),
)
const StandardLayout = React.lazy(
  () => import("@/layouts/bottom-menu-layout/bottom-menu-layout.tsx"),
)
const FarmPermissions = React.lazy(
  () => import("@/routing/farm-permissions.tsx"),
)
const ProtectedRoute = React.lazy(() => import("@/routing/protected-route.tsx"))
const AnimalEventView = React.lazy(
  () =>
    import(
      "@/features/animal-events/routes/animal-event-view/animal-event-view"
    ),
)
const NewAnimalEventView = React.lazy(
  () =>
    import(
      "@/features/animal-events/routes/new-animal-event-view/new-animal-event-view"
    ),
)
const AssistantPage = React.lazy(
  () => import("@/features/assistant/routes/assistant-page/assistant-page.tsx"),
)
const AnimalProfile = React.lazy(
  () => import("@/features/farm/routes/animal-profile/animal-profile.tsx"),
)
const AnimalView = React.lazy(
  () => import("@/features/farm/routes/animal-view/animal-view.tsx"),
)
const FarmLayout = React.lazy(
  () => import("@/features/farm/routes/farm-layout/farm-layout"),
)
const NewAnimalView = React.lazy(
  () => import("@/features/farm/routes/new-animal-view/new-animal-view"),
)
const FinancePage = React.lazy(
  () => import("@/features/finance/routes/finance-page/finance-page"),
)
const NewTransactionView = React.lazy(
  () =>
    import(
      "@/features/finance/routes/new-transaction-view/new-transaction-view"
    ),
)
const TransactionHistoryPage = React.lazy(
  () =>
    import(
      "@/features/finance/routes/transaction-history-page/transaction-history-page"
    ),
)
const TransactionView = React.lazy(
  () => import("@/features/finance/routes/transaction-view/transaction-view"),
)
const AllCalendarsPage = React.lazy(
  () =>
    import(
      "@/features/gestational-calendar/routes/all-calendars-page/all-calendars-page"
    ),
)
const CalendarView = React.lazy(
  () =>
    import(
      "@/features/gestational-calendar/routes/calendar-view/calendar-view"
    ),
)
const NewCalendarView = React.lazy(
  () =>
    import(
      "@/features/gestational-calendar/routes/new-calendar-view/new-calendar-view"
    ),
)

const Home = lazy(() => import("@/features/home/routes/home/home"))
const SettingsPage = lazy(
  () =>
    import(
      "@/features/settings-account/routes/settings-page/settings-page.tsx"
    ),
)
const AnimalEventsPage = lazy(
  () => import("@/features/animal-events/routes/events-page/events-page"),
)

Sentry.init({
  dsn: sentryConfig.dsn,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: sentryConfig.tracesSampleRate,
  tracePropagationTargets: [import.meta.env.VITE_API_URL],
  // Session Replay
  replaysSessionSampleRate: sentryConfig.replaysSessionSampleRate,
  replaysOnErrorSampleRate: sentryConfig.replaysOnErrorSampleRate,
  enabled: import.meta.env.PROD,
  environment: import.meta.env.MODE,
})

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

function GlobalOutlet() {
  return (
    <>
      <ScrollRestoration />
      <ModalPortal />
      <ToastConfig />
      <WideScreenMessage />
      <LandscapeBlock />
      <Outlet />
    </>
  )
}

function FarmEnabledLayout() {
  const isMammal = useFarmHasMammals()
  return isMammal ? <Outlet /> : <Navigate to={"/home"} replace />
}

function BeeModuleEnabled() {
  const { permissions } = useFarmPermissions()
  return (
    <Suspense fallback={<LoadingAnimation />}>
      <ProtectedRoute
        redirectRoute={NOT_ENABLED}
        condition={permissions?.bee_module}
      />
    </Suspense>
  )
}

function FinanceJournalEnabled() {
  const { permissions } = useFarmPermissions()
  return (
    <Suspense fallback={<LoadingAnimation />}>
      <ProtectedRoute
        redirectRoute={NOT_ENABLED}
        condition={permissions?.finance_journal_module}
      />
    </Suspense>
  )
}

function AIAssistantEnabled() {
  const { permissions } = useFarmPermissions()
  return (
    <Suspense fallback={<LoadingAnimation />}>
      <ProtectedRoute
        redirectRoute={NOT_ENABLED}
        condition={permissions?.ai_assistant_module}
      />
    </Suspense>
  )
}

function GestationalCalendarEnabled() {
  const { permissions } = useFarmPermissions()
  return (
    <Suspense fallback={<LoadingAnimation />}>
      <ProtectedRoute
        redirectRoute={NOT_ENABLED}
        condition={permissions?.gestational_calendar_module}
      />
    </Suspense>
  )
}

function AuthenticatedRoutes() {
  const auth = useAuth()
  return (
    <Suspense fallback={<LoadingAnimation />}>
      <ProtectedRoute
        redirectRoute={LOGIN_SCREEN_PATH}
        condition={Boolean(auth.token)}
      />
    </Suspense>
  )
}

const withSuspense = (
  Component: React.ReactNode,
  fallback = <LoadingAnimation />,
) => <Suspense fallback={fallback}>{Component}</Suspense>

const bottomMenuLayoutRoutes = [
  { path: "/", element: <Navigate to="/home" /> },
  {
    index: true,
    path: "home",
    element: <Home />,
  },
  {
    path: "menu",
    element: <MenuPage />,
  },
  {
    path: NOTES_VIEW_PATH,
    element: <NotesLayout />,
  },
  {
    path: APIARIES_SCREEN_PATH,
    element: <AllApiariesTab />,
  },
  {
    path: TASKS_SCREEN_PATH,
    element: <TaskLayout />,
  },
  {
    path: NOTIFICATIONS_PATH,
    element: <NotificationsPage />,
    children: [
      {
        path: ":notificationId",
        element: <NotificationView />,
      },
    ],
  },
  {
    element: <FarmEnabledLayout />,
    children: [
      {
        path: FARM_SCREEN_PATH,
        element: <FarmLayout />,
      },
    ],
  },
]

// Routes under NoMenuLayout
const noMenuLayoutRoutes = [
  {
    path: SETTINGS_SCREEN_PATH,
    element: <SettingsPage />,
  },
  {
    path: EDIT_FARM_PROFILE_PATH,
    element: <FarmProfilePage />,
  },
  {
    path: VIEW_COMPANY_INFO_PATH,
    element: <CompanyInfoPage />,
  },
  {
    path: FAQ_PATH,
    element: <SettingsFaq />,
  },
  {
    path: EDIT_MY_PROFILE_PATH,
    element: <UserProfileWrapper />,
  },
  {
    path: RESET_PASSWORD_PATH,
    element: <SettingsResetPassword />,
  },
  {
    path: TUTORIAL_PATH,
    element: <SettingsTutorial />,
  },
  {
    path: CONFIDENTIALITY_PATH,
    element: <SettingsConfidentiality />,
  },
  {
    path: CONTACT_PATH,
    element: <SettingsContact />,
  },
  {
    path: ACCOUNT_SCREEN_PATH,
    element: <AccountTab />,
  },
  {
    path: TERMS_PATH,
    element: <SettingsTerms />,
  },
  {
    path: NOTIFICATION_PREFERENCES_PATH,
    element: <NotificationPreferences />,
  },
  {
    path: NOT_ENABLED,
    element: <NotEnabled />,
  },
  // Notes routes
  {
    path: `${NEW_NOTE_SCREEN_PATH}/:animalId`,
    element: <NewNoteView />,
  },
  {
    path: NEW_NOTE_SCREEN_PATH,
    element: <NewNoteView />,
  },
  {
    path: `${NOTE_SCREEN_PATH}/:noteId`,
    element: <NoteEditorView />,
  },
  // Tasks routes
  {
    path: `${NEW_TASK_SCREEN_PATH}/:animalId`,
    element: <NewTaskView />,
  },
  {
    path: NEW_TASK_SCREEN_PATH,
    element: <NewTaskView />,
  },
  {
    path: `${TASKS_SCREEN_PATH}/:taskId`,
    element: <TaskView />,
  },
  // Animal routes under FarmEnabledLayout
  {
    element: <FarmEnabledLayout />,
    children: [
      {
        path: NEW_ANIMAL_SCREEN_PATH,
        element: <NewAnimalView />,
      },
      {
        path: `${ANIMAL_SCREEN_PATH}/:animalId`,
        element: <AnimalView />,
      },
      {
        path: `${ANIMAL_PROFILE_PATH}/:animalId`,
        element: <AnimalProfile />,
      },
    ],
  },
  // Animal event routes
  {
    path: ANIMAL_EVENT_SCREEN_PATH,
    element: <AnimalEventsPage />,
  },
  {
    path: `${NEW_ANIMAL_EVENT_SCREEN_PATH}`,
    element: <NewAnimalEventView />,
  },
  {
    path: `${ANIMAL_EVENT_SCREEN_PATH}/:animalEventId`,
    element: <AnimalEventView />,
  },
  {
    path: `${NEW_ANIMAL_EVENT_SCREEN_PATH}/:animalId?/:typeId?`,
    element: <NewAnimalEventView />,
  },
  // Finance routes under FinanceJournalEnabled
  {
    element: <FinanceJournalEnabled />,
    children: [
      {
        path: FINANCIAL_SCREEN_PATH,
        element: <FinancePage />,
      },
      {
        path: FINANCE_TRANSACTIONS_HISTORY_SCREEN_PATH,
        element: <TransactionHistoryPage />,
      },
      {
        path: NEW_TRANSACTION_SCREEN_PATH,
        element: <NewTransactionView />,
      },
      {
        path: `${FINANCE_TRANSACTIONS_SCREEN_PATH}/:transactionId`,
        element: <TransactionView />,
      },
    ],
  },
  // AI Assistant routes under AIAssistantEnabled
  {
    element: <AIAssistantEnabled />,
    children: [
      {
        path: AI_ASSISTANT_SCREEN_PATH,
        element: <AssistantPage />,
      },
    ],
  },
  // Gestational Calendar routes under GestationalCalendarEnabled
  {
    element: <GestationalCalendarEnabled />,
    children: [
      {
        path: ALL_GESTATIONAL_CALCULATOR_SCREEN_PATH,
        element: <AllCalendarsPage />,
      },
      {
        path: `${NEW_GESTATIONAL_CALCULATOR_SCREEN_PATH}/:gender?/:animalId?`,
        element: <NewCalendarView />,
      },
      {
        path: `${GESTATIONAL_CALCULATOR_SCREEN_PATH}/:calendarId`,
        element: <CalendarView />,
      },
    ],
  },

  // Routes available if Bee Module is Enabled that use the NoMenuLayout
  {
    element: <BeeModuleEnabled />,
    children: [
      {
        path: APIARY_VIEW,
        element: <ApiaryView />,
      },
      {
        path: NEW_APIARY_SCREEN_PATH,
        element: <NewApiaryView />,
      },
      {
        path: EDIT_APIARY_SCREEN_PATH,
        element: <EditApiaryView />,
      },
      {
        path: ALL_HIVES_PATH,
        element: <AllHives />,
      },
      {
        path: NEW_HIVE_FOR_APIARY,
        element: <NewHiveView />,
      },
      {
        path: NEW_HIVE,
        element: <NewHiveView />,
      },
      {
        path: HIVE_VIEW,
        element: <HiveView />,
      },
      {
        path: NEW_QUEEN_FOR_HIVE,
        element: <NewQueenView />,
      },
      {
        path: EDIT_HIVE_VIEW,
        element: <EditHiveView />,
      },
      {
        path: APIARY_HIVE_QUEEN,
        element: <QueenView />,
      },
      {
        path: EDIT_QUEEN_VIEW,
        element: <EditQueenView />,
      },
      {
        path: NEW_QUEEN_FOR_HIVE,
        element: <NewQueenView />,
      },
      {
        path: EDIT_HIVE_VIEW,
        element: <EditHiveView />,
      },
    ],
  },
]

// Unauthenticated routes
const unauthenticatedRoutes = [
  { path: LOGIN_SCREEN_PATH, element: <LoginView /> },
  { path: LOST_PASSWORD_PATH, element: <LostPassword /> },
  { path: "*", element: <NotFoundPage /> },
]

export const router = sentryCreateBrowserRouter(
  [
    {
      path: "/",
      element: <GlobalOutlet />,
      errorElement: (
        <Sentry.ErrorBoundary fallback={<ErrorBoundary />}>
          <ErrorBoundary />
        </Sentry.ErrorBoundary>
      ),
      children: [
        {
          element: <AuthenticatedRoutes />,
          children: [
            {
              path: "/",
              element: <FarmPermissions />,
              children: [
                {
                  element: withSuspense(<StandardLayout />),
                  children: bottomMenuLayoutRoutes,
                },
                {
                  element: <NoMenuLayout />,
                  children: noMenuLayoutRoutes,
                },
              ],
            },
          ],
        },
        {
          element: <NoMenuLayout enableSuspense={false} />,
          children: unauthenticatedRoutes,
        },
      ],
    },
  ],
  {},
)
