import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

export const HiveManufacturerIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.9375 2.75C6.75516 2.75 6.5803 2.82243 6.45136 2.95136C6.32243 3.0803 6.25 3.25516 6.25 3.4375C6.25 3.61984 6.32243 3.7947 6.45136 3.92364C6.5803 4.05257 6.75516 4.125 6.9375 4.125H11.0625C11.2448 4.125 11.4197 4.05257 11.5486 3.92364C11.6776 3.7947 11.75 3.61984 11.75 3.4375C11.75 3.25516 11.6776 3.0803 11.5486 2.95136C11.4197 2.82243 11.2448 2.75 11.0625 2.75H6.9375ZM13.125 11C13.125 12.094 12.6904 13.1432 11.9168 13.9168C11.1432 14.6904 10.094 15.125 9 15.125C7.90598 15.125 6.85677 14.6904 6.08318 13.9168C5.3096 13.1432 4.875 12.094 4.875 11C4.875 9.90598 5.3096 8.85677 6.08318 8.08318C6.85677 7.3096 7.90598 6.875 9 6.875C10.094 6.875 11.1432 7.3096 11.9168 8.08318C12.6904 8.85677 13.125 9.90598 13.125 11Z"
        fill="white"
      />
      <path
        d="M4.1875 0C3.27582 0 2.40148 0.362164 1.75682 1.00682C1.11216 1.65148 0.75 2.52582 0.75 3.4375V19.25C0.75 19.9793 1.03973 20.6788 1.55546 21.1945C2.07118 21.7103 2.77065 22 3.5 22H14.5C15.2293 22 15.9288 21.7103 16.4445 21.1945C16.9603 20.6788 17.25 19.9793 17.25 19.25V3.4375C17.25 2.52582 16.8878 1.65148 16.2432 1.00682C15.5985 0.362164 14.7242 0 13.8125 0L4.1875 0ZM2.125 3.4375C2.125 2.89049 2.3423 2.36589 2.72909 1.97909C3.11589 1.5923 3.64049 1.375 4.1875 1.375H13.8125C14.3595 1.375 14.8841 1.5923 15.2709 1.97909C15.6577 2.36589 15.875 2.89049 15.875 3.4375V18.2806C15.5451 18.0168 15.1873 17.7899 14.808 17.6041C13.664 17.0321 11.8366 16.5 9 16.5C6.16337 16.5 4.336 17.0321 3.192 17.6041C2.81266 17.7899 2.45486 18.0168 2.125 18.2806V3.4375Z"
        fill="white"
      />
    </svg>
  )
}
