import { skipToken } from "@reduxjs/toolkit/query"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import EditIcon from "@/assets/icons/misc/edit-icon.tsx"
import genericQueenImg from "@/assets/images/generic-queen.webp"
import GenericPicture from "@/components/generic-picture/generic-picture.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import EventsWidget from "@/features/animal-events/components/events-widget/events-widget.tsx"
import { useGetQueenByIdQuery } from "@/features/bees/api/apiaries-api.ts"
import { QueenDetails } from "@/features/bees/components/queen-details/queen-details.tsx"
import TasksWidget from "@/features/home/components/tasks-widget/tasks-widget.tsx"
import NotesWidget from "@/features/notes/components/notes-widget/notes-widget.tsx"
import {
  ALL_HIVES_PATH,
  APIARIES_SCREEN_PATH,
} from "@/utils/constants/routes.ts"

export const QueenView = () => {
  const { t } = useTranslation()
  const { apiaryId, hiveId, queenId } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const { data: queen } = useGetQueenByIdQuery(
    hiveId && apiaryId && queenId
      ? {
          apiaryId,
          hiveId,
          queenId,
        }
      : skipToken,
  )

  const title = queen?.name || t("isLoading")
  const editQueenNavigationString = `${APIARIES_SCREEN_PATH}/${apiaryId}${ALL_HIVES_PATH}/${hiveId}/queen/${queenId}/edit`

  const handleBackClick = () => {
    if (location.state?.previousPath?.includes("new")) {
      navigate(
        `${APIARIES_SCREEN_PATH}/${apiaryId}${ALL_HIVES_PATH}/${hiveId}`,
        {
          state: { previousPath: location.pathname },
        },
      )
    } else {
      navigate(-1)
    }
  }

  return (
    <>
      <MobileTopBar
        title={title}
        icon={<EditIcon />}
        navigateTo={editQueenNavigationString}
        onBackClick={handleBackClick}
      />
      <PageContentWrapperLarge className={"flex flex-col gap-4 p-4"}>
        <GenericPicture
          height={200}
          src={queen?.picture || genericQueenImg}
          alt={t("hiveImage")}
          borderRadius={12}
        />
        {queen && <QueenDetails queen={queen} />}
        <TasksWidget tasks={queen?.tasks} queenId={queen?.id} />
        <NotesWidget notes={queen?.notes} queenId={queen?.id} />
        <EventsWidget animalId={queen?.id || ""} />
      </PageContentWrapperLarge>
    </>
  )
}
