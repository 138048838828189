import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const ClockIcon: React.FC<GeneralSvgProps> = (props) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
  >
    <path
      d="M7.40536 4.0013V7.5013L9.73869 8.66797M13.2387 7.5013C13.2387 10.723 10.627 13.3346 7.40536 13.3346C4.18369 13.3346 1.57202 10.723 1.57202 7.5013C1.57202 4.27964 4.18369 1.66797 7.40536 1.66797C10.627 1.66797 13.2387 4.27964 13.2387 7.5013Z"
      className="stroke-current"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ClockIcon
