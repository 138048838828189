import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
// import ClockIcon from "@/assets/icons/misc/clock-icon.tsx"
import CircleTickIcon from "@/assets/icons/task/circle-tick-icon.tsx"
import PendingCircle from "@/components/pending-circle/pending-circle.tsx"
import { useNotesContext } from "@/context/notes-context.tsx"
import { type Note } from "@/features/notes/types/note.ts"
import { useAppSelector } from "@/redux/hooks.ts"
import { BROWN } from "@/utils/constants/colors-constants.ts"
import { NOTE_SCREEN_PATH } from "@/utils/constants/routes.ts"
import { extractNoteContentPlainText } from "@/utils/helpers/string-utils.ts"
// import { formatDate } from "@/utils/helpers/time-utils.ts"
import { cn } from "@/utils/styles.ts"

interface NoteCardProps {
  note: Note
}

const NoteCard: React.FC<NoteCardProps> = ({ note }) => {
  const { t } = useTranslation()
  const { isContextEnabled, setSelectedNotes, selectedNotes } =
    useNotesContext()
  const isSelected = (note: Note) => {
    return selectedNotes && selectedNotes.includes(note.id)
  }

  const handleNoteSelection = (
    event: React.MouseEvent<HTMLAnchorElement | HTMLDivElement>,
  ) => {
    if (!isContextEnabled) return
    event.preventDefault()
    if (isSelected(note)) {
      setSelectedNotes(
        selectedNotes ? selectedNotes?.filter((id) => id !== note.id) : [],
      )
    } else {
      setSelectedNotes(selectedNotes ? [...selectedNotes, note.id] : [note.id])
    }
  }

  const draftNotes = useAppSelector((state) => state.notesDraftSlice.drafts)
  const hasDraft = Object.keys(draftNotes).some((draft) => draft === note.id)

  const computedClassName = hasDraft
    ? "grid grid-cols-[auto_auto] items-center justify-between gap-6 pt-2"
    : "pt-2"

  return (
    <Link
      to={`${NOTE_SCREEN_PATH}/${note.id}`}
      className={cn("card-wrapper", "flex h-fit min-h-[60px] bg-white p-4")}
      onClick={handleNoteSelection}
    >
      {isContextEnabled && (
        <div className={"pr-4"}>
          {isSelected(note) ? (
            <CircleTickIcon size={24} fill={BROWN} />
          ) : (
            <PendingCircle />
          )}
        </div>
      )}
      <div className={"w-full"}>
        <div className={"grid grid-cols-[auto_auto] justify-between gap-2"}>
          <div
            className={"truncate text-[16px] font-semibold text-neutral-700"}
          >
            {note.title}
          </div>
          {/*<div*/}
          {/*  className={*/}
          {/*    "flex w-full items-center justify-center gap-1.5 text-neutral-500"*/}
          {/*  }*/}
          {/*>*/}
          {/*  <ClockIcon />*/}
          {/*  {formatDate(note.updated_at)}*/}
          {/*</div>*/}
        </div>
        <div className={computedClassName}>
          <div
            className={
              "h-4 overflow-hidden text-ellipsis whitespace-nowrap text-neutral-700"
            }
          >
            {extractNoteContentPlainText(note, 150)}
          </div>
          {hasDraft && (
            <i className={"text-right text-gray-500"}>
              {t("unsavedChangesIndicator")}
            </i>
          )}
        </div>
      </div>
    </Link>
  )
}

export default NoteCard
