import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import ClockIcon from "@/assets/icons/misc/clock-icon.tsx"
import EmptyCalendarIcon from "@/assets/icons/misc/empty-calendar-icon.tsx"
import RoundedCheckDone from "@/assets/icons/misc/rounded-check-done.tsx"
import RoundedCheck from "@/assets/icons/misc/rounded-check.tsx"
import { useGetAnimalsQuery } from "@/features/farm/api/animals-api.ts"
import { getTaskCardStyle, getTaskState, TASK_STATES } from "@/features/tasks"
import { useToggleTaskStatusMutation } from "@/features/tasks/api/tasks-api.ts"
import TaskCardPillAnimalInfo from "@/features/tasks/components/task-card-pills/task-card-pill-animal-info.tsx"
import { TaskCardPillApiary } from "@/features/tasks/components/task-card-pills/task-card-pill-apiary.tsx"
import TaskCardPillCategoryInfo from "@/features/tasks/components/task-card-pills/task-card-pill-category-info.tsx"
import { TaskCardPillHive } from "@/features/tasks/components/task-card-pills/task-card-pill-hive.tsx"
import { TaskCardPillQueen } from "@/features/tasks/components/task-card-pills/task-card-pill-queen.tsx"
import { useGetTaskCategory } from "@/features/tasks/hooks/use-get-task-category.ts"
import { useGetTaskDateHour } from "@/features/tasks/hooks/use-get-task-date-hour.ts"
import { type Task } from "@/features/tasks/types/task.ts"
import { useAppDispatch, useAppSelector } from "@/redux/hooks.ts"
import { setSelectedTasksIds } from "@/redux/slices/task-selection-slice.ts"
import { TASKS_SCREEN_PATH } from "@/utils/constants/routes.ts"
import { cn } from "@/utils/styles.ts"

interface TaskCardProps {
  task: Task
}

const TaskCard: React.FC<TaskCardProps> = ({ task }) => {
  const { t } = useTranslation()
  const { formattedDate, formattedHour } = useGetTaskDateHour(task)
  const navigate = useNavigate()
  const { data = [] } = useGetAnimalsQuery()
  const [toggleTaskStatus] = useToggleTaskStatusMutation()
  const dispatch = useAppDispatch()
  const selectedTasks = useAppSelector(
    (state) => state.taskSelectionSlice.selectedTasksIds,
  )
  const isSelectionEnabled = useAppSelector(
    (state) => state.taskSelectionSlice.isSelectionEnabled,
  )
  const isSelected = (task: Task) => {
    return selectedTasks.includes(task.id)
  }

  const taskState = getTaskState(task, isSelected(task))
  const taskStyleClass = getTaskCardStyle(taskState)

  const taskCategory = useGetTaskCategory({ task })
  const taskAnimals = data?.filter((animal) =>
    task.animals?.includes(animal.id),
  )

  const handleToggleTaskStatus = (
    task: Task,
    event: React.MouseEvent<HTMLDivElement>,
  ) => {
    event.stopPropagation()
    event.preventDefault()

    if (isSelectionEnabled) {
      handleTaskSelection(event)
      return
    }

    toast.promise(toggleTaskStatus(task).unwrap(), {
      pending: t("updatingTask"),
      success: t("taskUpdated"),
      error: t("errorUpdatingTask"),
    })
  }

  const handleTaskSelection = (
    event: React.MouseEvent<HTMLAnchorElement | HTMLDivElement>,
  ) => {
    event.preventDefault()
    event.stopPropagation()
    if (!isSelectionEnabled) {
      navigate(`${TASKS_SCREEN_PATH}/${task.id}`)
      return
    }

    if (isSelected(task)) {
      dispatch(
        setSelectedTasksIds(selectedTasks.filter((id) => id !== task.id)),
      )
    } else {
      dispatch(setSelectedTasksIds([...selectedTasks, task.id]))
    }
  }

  const hourStyle =
    taskState === TASK_STATES.PAST ? "text-red-500" : "text-neutral-700"

  const capitalizedTitle =
    task.title.charAt(0).toUpperCase() + task.title.slice(1)

  return (
    <div onClick={handleTaskSelection} className={taskStyleClass}>
      <div className="min-h-[60px] w-full pb-4 pl-4 pr-0 pt-4">
        <div
          className={
            "box-border flex max-w-full items-center overflow-hidden text-ellipsis whitespace-nowrap pb-2 pr-2 text-neutral-900"
          }
        >
          <p className={"truncate text-[16px]"}>{capitalizedTitle}</p>
          {Object.hasOwn(task, "hasChanges") && (
            <i className={"text-[10px]"}>{t("unsavedChangesIndicator")}</i>
          )}
        </div>
        <div className="flex flex-col items-start justify-between gap-2 pr-2">
          <div
            className={
              "flex h-fit items-center justify-start gap-[25px] font-light"
            }
          >
            {formattedDate && formattedHour && (
              <div className={"flex items-center justify-center gap-2"}>
                <EmptyCalendarIcon
                  size={16}
                  color={
                    taskState === TASK_STATES.PAST
                      ? "var(--color-red-500)"
                      : undefined
                  }
                />
                <p
                  className={cn(
                    "flex items-center gap-[10px] text-[12px]",
                    hourStyle,
                  )}
                >
                  {formattedDate}
                  <div className={"flex items-center gap-1"}>
                    <ClockIcon className={cn("h-3 w-3", hourStyle)} />{" "}
                    {formattedHour}
                  </div>
                </p>
              </div>
            )}
            <div className="flex items-center gap-2">
              {taskCategory && (
                <TaskCardPillCategoryInfo taskCategory={taskCategory} />
              )}
              {taskAnimals && taskAnimals.length > 0 && (
                <TaskCardPillAnimalInfo animals={taskAnimals} />
              )}
              {task.hives && task.hives.length > 0 && (
                <TaskCardPillHive hives={task.hives} />
              )}
              {task.apiaries && task.apiaries.length > 0 && (
                <TaskCardPillApiary apiaries={task.apiaries} />
              )}
              {task.queens && task.queens.length > 0 && (
                <TaskCardPillQueen queens={task.queens} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={"h-fit p-4"}
        onClick={(e) => handleToggleTaskStatus(task, e)}
      >
        {task.is_done ? (
          <RoundedCheckDone className={"h-[25px] w-[25px]"} />
        ) : (
          <RoundedCheck className={"h-[25px] w-[25px]"} />
        )}
      </div>
    </div>
  )
}

export default TaskCard
