import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

export const ApiariesIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.07743 0L1.47583 4.84861L1.1566 5.05221L1.56284 5.69215L1.88207 5.48855L9.07743 0.898986L16.2728 5.48855L16.592 5.69215L16.9983 5.05221L16.679 4.84861L9.07743 0ZM2.98596 0.241853L2.62928 0.813495L3.27076 1.21563L3.62753 0.644071L2.98596 0.241769V0.241853ZM1.35157 0.955952L0.794622 1.46915L1.24967 1.9655L1.80674 1.4523L1.35157 0.955952ZM14.1783 1.41177L13.4277 1.50771L13.5127 2.17638L14.2633 2.08049L14.1783 1.41177ZM9.07743 1.57314L2.85064 5.54493L2.49194 6.81183H15.6492L15.2721 5.52449L9.07743 1.57314ZM9.07743 3.42058L11.4735 4.22114V6.22195H6.68133V4.22114L9.07743 3.42058ZM9.07743 4.21996L7.438 4.76771V5.46352H10.7169V4.76771L9.07743 4.21996ZM5.51162 5.00872L5.82113 5.60729L5.14938 5.95629L4.83983 5.35777L5.51157 5.00876L5.51162 5.00872ZM16.7238 6.93617L16.4295 7.63498L17.0491 7.89718L17.3435 7.1985L16.7238 6.93622L16.7238 6.93617ZM3.37127 7.57026L2.6537 10.4776H15.811L15.0906 7.57026H3.37127ZM4.66357 8.8343H13.4913V9.59273H4.66357V8.8343ZM0.739007 10.5276L0 10.6904L0.144354 11.3489L0.883362 11.1861L0.739007 10.5276ZM3.45236 11.236L2.57286 14.5225H15.8786L14.9959 11.236H3.45236ZM12.0158 11.6439L12.6387 12.0746L12.2567 12.6295L11.6339 12.1988L12.0158 11.6439ZM4.66357 12.8792H13.4913V13.6377H4.66357V12.8792ZM17.3772 13.6646L16.9952 14.2195L17.618 14.6503L18 14.0954L17.3771 13.6647L17.3772 13.6646ZM3.45227 15.2809L2.57278 18.5674H15.8786L14.9959 15.2809H3.45227ZM7.81078 15.4163L8.16767 15.9876L7.5264 16.3902L7.16942 15.8189L7.81078 15.4163ZM1.58016 15.898L1.29667 16.5094L1.98283 16.8291L2.26629 16.2178L1.58016 15.898ZM14.3232 15.9324L14.6306 16.5319L13.9575 16.8786L13.6502 16.279L14.3232 15.9324ZM4.66357 16.9242H13.4913V17.6826H4.66357V16.9242ZM3.69672 19.3258V20H5.71449V19.3258H3.69672ZM12.4404 19.3258V20H14.4581V19.3258H12.4404Z" />
    </svg>
  )
}
