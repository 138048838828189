import React from "react"
import PlaceholderIcon from "@/assets/icons/misc/placeholder-icon.tsx"
import GenericPicture from "@/components/generic-picture/generic-picture.tsx"
import { type TaskCategory } from "@/features/tasks/api/task-categories-api.ts"

interface TaskCardPillProps {
  taskCategory: TaskCategory
}

const TaskCardPillCategoryInfo: React.FC<TaskCardPillProps> = ({
  taskCategory,
}) => {
  return (
    <div className={"h-[25px] w-[25px]"}>
      {taskCategory.id !== "" && taskCategory.picture ? (
        <GenericPicture
          src={taskCategory.picture}
          alt={taskCategory.name}
          size={25}
          borderRadius={50}
        />
      ) : (
        <PlaceholderIcon size={25} />
      )}
    </div>
  )
}

export default TaskCardPillCategoryInfo
