import React, { Suspense } from "react"
import { Outlet } from "react-router-dom"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"

interface StandardLayoutProps {
  enableSuspense?: boolean
}

const NoMenuLayout: React.FC<StandardLayoutProps> = ({
  enableSuspense = true,
}) => {
  if (!enableSuspense) return <Outlet />
  return (
    <Suspense fallback={<LoadingAnimation />}>
      <Outlet />
    </Suspense>
  )
}

export default NoMenuLayout
