import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

export const TagIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M6.57964 13.1942L10.6609 9.11293M8.8058 15.4204L10.6609 13.5653M2.43453 11.4029L10.5971 3.24032C10.8754 2.96198 11.2528 2.80557 11.6464 2.80548H17.7104C18.104 2.80548 18.4815 2.96184 18.7599 3.24017C19.0382 3.51849 19.1945 3.89598 19.1945 4.28959V10.3536C19.1945 10.7472 19.038 11.1247 18.7597 11.4029L10.5971 19.5655C10.3188 19.8437 9.94138 20 9.54785 20C9.15432 20 8.7769 19.8437 8.49859 19.5655L2.43453 13.5014C2.1563 13.2231 2 12.8457 2 12.4522C2 12.0586 2.1563 11.6812 2.43453 11.4029Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.2263 6.51573C16.2263 6.71254 16.1481 6.90128 16.009 7.04045C15.8698 7.17961 15.681 7.25779 15.4842 7.25779C15.2874 7.25779 15.0987 7.17961 14.9595 7.04045C14.8204 6.90128 14.7422 6.71254 14.7422 6.51573C14.7422 6.31893 14.8204 6.13019 14.9595 5.99102C15.0987 5.85186 15.2874 5.77368 15.4842 5.77368C15.681 5.77368 15.8698 5.85186 16.009 5.99102C16.1481 6.13019 16.2263 6.31893 16.2263 6.51573Z"
        fill="white"
      />
    </svg>
  )
}
