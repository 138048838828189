import isEqual from "lodash.isequal"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import EditorTopBar from "@/components/top-bars/editor-top-bar/editor-top-bar.tsx"
import { useCreateApiaryMutation } from "@/features/bees/api/apiaries-api.ts"
import { ApiaryEditor } from "@/features/bees/components/apiary-editor/apiary-editor.tsx"
import { validateApiary } from "@/features/bees/types/apiaries-schema.tsx"
import {
  type ApiaryInput,
  apiaryInputInitialState,
} from "@/features/bees/types/apiaries.ts"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import useNavigationFallback from "@/hooks/use-navigation-fallback.ts"
import {
  ALL_HIVES_PATH,
  APIARIES_SCREEN_PATH,
} from "@/utils/constants/routes.ts"

const NewApiaryView = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [createApiaryMutation] = useCreateApiaryMutation()
  const { handleBackNavigation } = useNavigationFallback()
  const { openModal } = useModal()

  const handleCreateApiaryMutation = async () => {
    if (!(await validateApiary(defaultApiary))) {
      return
    }
    const promise = createApiaryMutation(defaultApiary).unwrap()
    toast
      .promise(promise, {
        pending: t("creatingApiary"),
        success: t("apiaryCreated"),
        error: t("apiaryCreateError"),
      })
      .then((response) => {
        navigate(`${APIARIES_SCREEN_PATH}/${response.id}`, {
          state: { previousPath: location.pathname },
        })
        openModal(MODALS.BASE_MODAL, {
          title: t("apiaryWasSuccessfullyAdded"),
          content: t("hiveStep"),
          onActionClick: () =>
            navigate(
              `${APIARIES_SCREEN_PATH}/${response.id}${ALL_HIVES_PATH}/new/`,
            ),
        })
      })
  }

  const [defaultApiary, setDefaultApiary] = useState<ApiaryInput>(
    apiaryInputInitialState,
  )

  const handleApiaryChange = (newApiary: ApiaryInput) => {
    setDefaultApiary(newApiary)
  }

  const hasChanges = !isEqual(apiaryInputInitialState, defaultApiary)

  return (
    <>
      <EditorTopBar
        text={t("addApiary")}
        isModified={hasChanges}
        onSave={handleCreateApiaryMutation}
        onBackClick={handleBackNavigation}
      />
      <PageContentWrapperLarge>
        <ApiaryEditor
          apiary={defaultApiary}
          onApiaryChange={handleApiaryChange}
        />
      </PageContentWrapperLarge>
    </>
  )
}

export default NewApiaryView
